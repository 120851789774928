import { useLayout } from 'hooks/useLayout'
import { number, object, boolean } from 'yup'
import { useEffect, useCallback, useState } from 'react'
import { Modal } from 'components/Modal/Modal'
import { useSnackbar } from 'stores/useSnackbar'
import { colorPrimitives } from 'components/Theme'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import { TextField } from 'components/Shared/TextField'
import type { components } from 'api/playerPayback/api'
import { Box, Button, Stack, Typography } from '@mui/material'
import { isPresent } from '@jjvgaming/player-payback-library'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { useQueryClient } from '@tanstack/react-query'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { ActivityButton } from 'components/ActivityButton'
import { WelcomeBackMilestoneBonusSwitch } from './WelcomBackMilestoneBonusSwitch'
import { DataTable } from 'components/Shared/DataTable'
import {
  type GridEventListener,
  type GridColDef,
  type GridRenderCellParams,
} from '@mui/x-data-grid'
import { useParams } from 'react-router-dom'
import { DeleteWelcomeBackMilestoneBonus } from './DeleteWelcomeBackMilestoneBonusRenderCell'
import { type CheckInPolicyWelcomeBackMilestonePointBonusScheduleDTO } from 'types/api'
import { DeleteWelcomeBackMilestoneBonusModal } from './DeleteWelcomeBackMilestoneBonusModal'
import { EditWelcomeBackMilestoneBonusModal } from './EditWelcomeBackMilestoneBonusModal'
import { useGetCheckInPolicyByRewardsProgramId } from 'hooks/api/useGetCheckInPolicyByRewardsProgramId'
import { UsePostCheckInPolicy } from 'hooks/api/UsePostCheckInPolicy'

interface WelcomBackMilestoneBonusSettingsForm {
  enablePatronWelcomeBackMilestoneBonus: boolean
  pointBonus: number
  numberOfWeeks: number
}

const WelcomBackMilestoneBonusSettingsSchema = object({
  enablePatronWelcomeBackMilestoneBonus: boolean().required(
    'Enable Patron Welcome Back Milestone Bonus is required.'
  ),
  pointBonus: number()
    .typeError('# of points must be a number.')
    .test(
      'is-positive-or-zero',
      'Value must be a positive number or zero',
      (value) => {
        return value === 0 || (typeof value === 'number' && value > 0)
      }
    )
    .required('Welcome Point Bonus value is required.'),
  numberOfWeeks: number()
    .typeError('# of weeks must be a number.')
    .test(
      'is-positive-or-zero',
      'Value must be a positive number or zero',
      (value) => {
        return value === 0 || (typeof value === 'number' && value > 0)
      }
    )
    .required('Welcome Point Bonus value is required.'),
})

export const WelcomBackMilestoneBonusSettingsModal = ({
  checkInPolicy,
  isModalVisible,
  toggleModalVisibility,
}: {
  checkInPolicy: components['schemas']['FullCheckInPolicyDTO']
  isModalVisible: boolean
  toggleModalVisibility: () => void
}) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : '75%'
  const setMessage = useSnackbar((state) => state.setMessage)
  const queryClient = useQueryClient()
  const { id: rewardsProgramId } = useParams()
  const [
    isWelcomeBackMilestoneBonusModalOpen,
    setIsWelcomeBackMilestoneBonusModalOpen,
  ] = useState(false)
  const [
    isDeleteWelcomeBackMilestoneBonusModalOpen,
    setIsDeleteWelcomeBackMilestoneBonusModalOpen,
  ] = useState(false)
  const [rowData, setRowData] =
    useState<CheckInPolicyWelcomeBackMilestonePointBonusScheduleDTO | null>()

  const toggleEditWelcomeBackMilestoneBonusModalOpen = () =>
    setIsWelcomeBackMilestoneBonusModalOpen(
      !isWelcomeBackMilestoneBonusModalOpen
    )

  const toggleDeleteWelcomeBackMilestoneBonusModalOpen = () =>
    setIsDeleteWelcomeBackMilestoneBonusModalOpen(
      !isDeleteWelcomeBackMilestoneBonusModalOpen
    )

  const formMethods = useForm<WelcomBackMilestoneBonusSettingsForm>({
    resolver: yupResolver(WelcomBackMilestoneBonusSettingsSchema),
  })

  const checkInPolicyByRewardsProgramIdQuery =
    useGetCheckInPolicyByRewardsProgramId({
      rewardsProgramId: Number(rewardsProgramId),
    })

  const welcomeBackMilestoneBonuschedulesData =
    checkInPolicyByRewardsProgramIdQuery.data
      ?.checkInPolicyWelcomeBackMilestonePointBonusSchedules ?? []

  const selectCell: GridEventListener<'cellClick'> = (params) => {
    if (params.field !== '__menu__') {
      toggleEditWelcomeBackMilestoneBonusModalOpen() // only open the edit modal on the non-menu cells
    }
    setRowData(params.row)
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    reset,
  } = formMethods

  const resetForm = useCallback(() => {
    reset({
      enablePatronWelcomeBackMilestoneBonus: Boolean(
        checkInPolicy?.enablePatronWelcomeBackMilestoneBonus
      ),
      pointBonus: 0,
      numberOfWeeks: 0,
    })
  }, [checkInPolicy])

  useEffect(() => {
    resetForm()
  }, [resetForm])

  const enablePatronWelcomeBackMilestoneBonus = watch(
    'enablePatronWelcomeBackMilestoneBonus'
  )

  const closeModal = () => {
    toggleModalVisibility()
    resetForm()
  }

  const usePostCheckInPolicyWelcomeBackMilestonePointBonusSchedule =
    UsePostCheckInPolicy({})

  useEffect(() => {
    if (usePostCheckInPolicyWelcomeBackMilestonePointBonusSchedule.isSuccess) {
      queryClient
        .invalidateQueries({
          queryKey: [
            '/admin/policies/checkins/rewards-programs',
            checkInPolicy.rewardsProgramId,
          ],
        })
        .finally(() => {})
      setMessage('Bonus Points have been updated.', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      closeModal()
    } else {
      const error: any =
        usePostCheckInPolicyWelcomeBackMilestonePointBonusSchedule.error as Error
      if (error?.message) {
        setMessage(error?.message, 'error', {
          vertical: 'top',
          horizontal: 'right',
        })
      }
    }
  }, [
    usePostCheckInPolicyWelcomeBackMilestonePointBonusSchedule.isSuccess,
    usePostCheckInPolicyWelcomeBackMilestonePointBonusSchedule.isError,
  ])

  const updateBonusPoints = handleSubmit((data) => {
    // get the list of existing Welcome Back Milestone Bonus Schedules
    const existingSchedules =
      checkInPolicy.checkInPolicyWelcomeBackMilestonePointBonusSchedules ?? []

    const updatedCheckInPolicy = {
      ...checkInPolicy,
      enablePatronWelcomeBackMilestoneBonus:
        data.enablePatronWelcomeBackMilestoneBonus,
      checkInPolicyWelcomeBackMilestonePointBonusSchedules: existingSchedules,
    }

    // if the pointBonus = 0 & numberOfWeeks = 0 or enablePatronWelcomeBackMilestoneBonus is false, only update the enablePatronWelcomeBackMilestoneBonus status on the existing schedules,
    // otherwise add a new schedule to the list of existing schedules
    const newSchedules =
      (data.pointBonus === 0 && data.numberOfWeeks === 0) ||
      !data.enablePatronWelcomeBackMilestoneBonus
        ? existingSchedules.map((schedule) => ({
            ...schedule,
            enablePatronWelcomeBackMilestoneBonus:
              data.enablePatronWelcomeBackMilestoneBonus,
          }))
        : [
            ...existingSchedules,
            {
              pointBonus: data.pointBonus,
              patronCheckInLatestNumWeeks: data.numberOfWeeks,
            },
          ]

    usePostCheckInPolicyWelcomeBackMilestonePointBonusSchedule.mutate({
      ...updatedCheckInPolicy,
      checkInPolicyWelcomeBackMilestonePointBonusSchedules: newSchedules,
    })
  })

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 150,
    },
    {
      field: 'checkInPolicyId',
      headerName: 'Checkin Policy ID',
      minWidth: 50,
      flex: 0.25,
    },
    {
      field: 'patronCheckInLatestNumWeeks',
      headerName: 'Number of Weeks',
      minWidth: 50,
      flex: 0.25,
    },
    {
      field: 'pointBonus',
      headerName: 'Point Bonus',
      minWidth: 50,
      flex: 0.25,
    },
    {
      field: '__menu__',
      headerName: '',
      align: 'left',
      renderCell: (
        params: GridRenderCellParams<CheckInPolicyWelcomeBackMilestonePointBonusScheduleDTO>
      ) => {
        return (
          <DeleteWelcomeBackMilestoneBonus
            params={params}
            setRowData={setRowData}
            toggleIsOpen={toggleDeleteWelcomeBackMilestoneBonusModalOpen}
          />
        )
      },
    },
  ]

  return (
    <Modal
      isOpen={isModalVisible}
      toggleIsOpen={() => {}}
      sx={{
        width: isMobile ? '100%' : '70%',
        height: heightModal,
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={updateBonusPoints}>
          <ModalFullHeader
            title="Welcome Back Milestone Bonus Points"
            subTitle={checkInPolicy.rewardsProgram?.name}
            toggleIsOpen={closeModal}
          />
          <ModalFullBody heightModal={heightModal}>
            <Stack spacing={5}>
              <WelcomeBackMilestoneBonusSwitch />

              {enablePatronWelcomeBackMilestoneBonus && (
                <Box className="flex flex-col gap-3">
                  <Box className="flex flex-col gap-3 mb-4">
                    <Typography>Claim your Welcome Back Bonus!</Typography>

                    <Typography>
                      {`We haven't seen you in a while! Check in within the
                      next # days and we'll award you with # additional J&J
                      Points!`}
                    </Typography>
                  </Box>
                  <Box className="flex flex-col sm:flex-row justify-stretch w-full px-1">
                    <Box className="w-full sm:w-1/2 sm:pt-4 flex">
                      <Typography># of Weeks</Typography>
                      <Typography color={colorPrimitives.redGaming}>
                        *
                      </Typography>
                    </Box>
                    <Box className="flex flex-row gap-6 w-full sm:w-1/2">
                      <TextField
                        placeholder="Weeks"
                        {...register('numberOfWeeks', { required: true })}
                        error={isPresent(errors.numberOfWeeks)}
                        sx={{ width: '100px' }}
                        bottomContent={
                          errors.numberOfWeeks?.message && (
                            <FormFieldErrorMessage
                              message={errors.numberOfWeeks.message}
                            />
                          )
                        }
                      />
                    </Box>
                  </Box>

                  <Box className="flex flex-col sm:flex-row justify-stretch w-full px-1 ">
                    <Box className="w-full sm:w-1/2 sm:pt-4 flex">
                      <Typography># of Points</Typography>
                      <Typography color={colorPrimitives.redGaming}>
                        *
                      </Typography>
                    </Box>
                    <Box className="flex flex-row gap-6 w-full sm:w-1/2">
                      <TextField
                        placeholder="Points"
                        {...register('pointBonus', { required: true })}
                        error={isPresent(errors.pointBonus)}
                        sx={{ width: '100px' }}
                        bottomContent={
                          errors.pointBonus?.message && (
                            <FormFieldErrorMessage
                              message={errors.pointBonus.message}
                            />
                          )
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              )}
              <DataTable
                data-testid="custom-data-table"
                columns={columns}
                rows={
                  Array.isArray(welcomeBackMilestoneBonuschedulesData)
                    ? welcomeBackMilestoneBonuschedulesData
                    : []
                }
                onCellClick={selectCell}
              />
            </Stack>
          </ModalFullBody>
          <ModalFullFooter>
            <Button variant="text" onClick={closeModal}>
              Cancel
            </Button>
            <ActivityButton
              active={
                usePostCheckInPolicyWelcomeBackMilestonePointBonusSchedule.isPending
              }
              variant="contained"
              type="submit"
            >
              Save
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </FormProvider>

      <EditWelcomeBackMilestoneBonusModal
        data={rowData}
        checkInPolicy={checkInPolicy}
        enablePatronWelcomeBackMilestoneBonus={
          enablePatronWelcomeBackMilestoneBonus
        }
        isModalOpen={isWelcomeBackMilestoneBonusModalOpen}
        toggleIsOpen={toggleEditWelcomeBackMilestoneBonusModalOpen}
      />

      <DeleteWelcomeBackMilestoneBonusModal
        data={rowData}
        checkInPolicy={checkInPolicy}
        enablePatronWelcomeBackMilestoneBonus={
          enablePatronWelcomeBackMilestoneBonus
        }
        welcomeBackMilestoneBonuschedulesData={
          welcomeBackMilestoneBonuschedulesData
        }
        isModalOpen={isDeleteWelcomeBackMilestoneBonusModalOpen}
        toggleIsOpen={toggleDeleteWelcomeBackMilestoneBonusModalOpen}
      />
    </Modal>
  )
}
