import { useState } from 'react'
import { isNil } from 'lodash'
import { useParams } from 'react-router-dom'
import { Box, Chip, Grid, Typography } from '@mui/material'
import green from '@mui/material/colors/green'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { SectionHeader } from 'components/SectionHeader'
import { SimpleImageCard } from 'components/ImageCard/SimpleImageCard'
import { formatDateTime, formatTypeName, getLastUpdated } from 'utils/util'
import { useGetRewardItemById } from 'hooks/api/useGetRewardItemById'
import { useGetRewardsOptionsById } from 'hooks/api/useGetRewardsOptionsById'
import { EditRewardItem } from './EditRewardItem'
import { EditRewardOptionsModal } from './EditRewardOptionsModal'
import { UploadImageRewardItem } from './UploadImageRewardItem'
import { type RewardsOptionsSize } from 'utils/constants/rewardsOptionsSize'

export const RewardItemsDetailPage = () => {
  const params = useParams()
  const [isImageModalOpen, setIsImageModalOpen] = useState(false)
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false)
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(false)

  const toggleImageModalIsOpen = async () => {
    setIsImageModalOpen(!isImageModalOpen)
  }

  const toggleDetailsModalIsOpen = async () => {
    setIsDetailsModalOpen(!isDetailsModalOpen)
  }

  const toggleOptionsModalIsOpen = async () => {
    setIsOptionsModalOpen(!isOptionsModalOpen)
  }

  const rewardItemQuery = useGetRewardItemById({
    rewardItemId: Number(params.id),
  })

  const rewardsOptionsQuery = useGetRewardsOptionsById({
    rewardId: Number(params.id),
    type: 'Size',
    enableJJStore: rewardItemQuery.data?.type === 'JJStoreItem',
  })

  // When using a conditionally enabled API call (useGetRewardsOptionsById), use .isLoading when displaying an ActivityIndicator during loading
  // https://tanstack.com/query/latest/docs/framework/react/guides/disabling-queries
  if (rewardItemQuery.isPending || rewardsOptionsQuery.isLoading) {
    return <ActivityIndicator />
  }
  if (rewardItemQuery.isError || rewardsOptionsQuery.isError) {
    return <Typography>There was an error</Typography>
  }

  const reward = rewardItemQuery.data
  const rewardsOptions = rewardsOptionsQuery.data
  const rewardsOptionsValues =
    isNil(rewardsOptions) ||
    isNil(rewardsOptions?.value) ||
    rewardsOptions?.value === ''
      ? []
      : rewardsOptions?.value?.split(',')

  // sort values in specific order using the order object
  const order = {
    Small: 1,
    Medium: 2,
    Large: 3,
    XL: 4,
    default: Number.MAX_VALUE,
  }

  const orderedValueArray: RewardsOptionsSize[] = rewardsOptionsValues
    ?.sort((a: string | undefined, b: string | undefined) => {
      const keyA = a as keyof typeof RewardsOptionsSize | undefined
      const keyB = b as keyof typeof RewardsOptionsSize | undefined

      // Ensure keyA and keyB are not undefined before comparison
      if (keyA !== undefined && keyB !== undefined) {
        const comparison =
          order[keyA] ||
          order.default - (order[keyB] || order.default) ||
          keyA.localeCompare(keyB)

        return comparison
      }

      // Return 0 if either key is undefined
      return 0
    })
    .map((value: string) => value as RewardsOptionsSize)

  return (
    <Page
      header={
        <PageHeader
          title={reward?.name ?? ''}
          backText="Reward Items"
          backPath="/Rewards/RewardItems"
          isSecondary={true}
          subtitle={getLastUpdated(reward?.modifiedOn)}
        />
      }
    >
      <Box className="gap-12 flex flex-col">
        <Box>
          <SectionHeader
            title="Details"
            onClickButton={toggleDetailsModalIsOpen}
          />
          <Box
            className="flex-1"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              rowGap: '16px',
              borderWidth: 1,
              borderColor: 'grey.300',
              borderRadius: 2,
              p: '24px',
            }}
          >
            <Grid className="flex-1">
              <div className="flex flex-col">
                <div className="pb-1 grid md:grid-cols-4">
                  <div>
                    <Typography variant="body-3">Reward Item Type</Typography>
                    <Typography variant="body-1">
                      {formatTypeName(reward?.type ?? '')}
                    </Typography>
                  </div>
                  <Grid item md={2}>
                    <Typography variant="body2" pb={1} color="text.secondary">
                      Start Date
                    </Typography>
                    <Typography variant="body1" pb={2}>
                      {reward?.startDate
                        ? formatDateTime(reward?.startDate, 'P p')
                        : 'None'}
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography variant="body2" pb={1} color="text.secondary">
                      End Date
                    </Typography>
                    <Typography variant="body1" pb={2}>
                      {reward?.endDate
                        ? formatDateTime(reward?.endDate, 'P p')
                        : 'None'}
                    </Typography>
                  </Grid>
                </div>
                <div className="pb-1 grid md:grid-cols-4">
                  <div>
                    <Typography variant="body-3">Description</Typography>
                    <Typography variant="body-1">
                      {reward?.description ? reward.description : '-'}
                    </Typography>
                  </div>
                  {formatTypeName(reward?.type ?? '') === 'J&J Store' && (
                    <>
                      <div className="mt-6 md:mt-0">
                        <Typography variant="body-3">
                          Vendor Item Name
                        </Typography>
                        <Typography variant="body-1">
                          {reward?.vendorItemName ? reward.vendorItemName : '-'}
                        </Typography>
                      </div>
                      <div className="mt-6 md:mt-0">
                        <Typography variant="body-3">SKU</Typography>
                        <Typography variant="body-1">
                          {reward.sku ? reward.sku : '-'}
                        </Typography>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Grid>
          </Box>
        </Box>

        {/* Image section */}
        <Box>
          <SectionHeader
            title="Image"
            onClickButton={toggleImageModalIsOpen}
            buttonText="Add/Remove Image"
          />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              rowGap: '16px',
              borderWidth: 1,
              borderColor: 'grey.300',
              borderRadius: 2,
              px: '16px',
              py: '16px',
              mt: '12px',
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{ pt: { xs: '24px', sm: 0 } }}
              >
                <Typography variant="body-3" className="w-full">
                  Thumbnail Image
                </Typography>
                <SimpleImageCard
                  imageSource={reward?.publicImageUrl}
                  sx={{ maxWidth: '358px', maxHeight: '358px' }}
                />
              </Grid>
            </Grid>
          </Box>

          <EditRewardItem
            isModalOpen={isDetailsModalOpen}
            toggleModalIsOpen={toggleDetailsModalIsOpen}
            reward={reward}
          />

          <UploadImageRewardItem
            isModalOpen={isImageModalOpen}
            toggleModalIsOpen={toggleImageModalIsOpen}
            reward={reward}
          />
        </Box>

        {/* Option section - JJStoreItem only */}
        {reward.type === 'JJStoreItem' && (
          <Box>
            <SectionHeader
              title="Options"
              onClickButton={toggleOptionsModalIsOpen}
              buttonText="Edit"
              chipText="No Options"
              chipInactive={isNil(rewardsOptions)}
            />

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                rowGap: '16px',
                borderWidth: 1,
                borderColor: 'grey.300',
                borderRadius: 2,
                px: '16px',
                py: '16px',
                mt: '12px',
              }}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  sx={{ pt: { xs: '24px', sm: 0 } }}
                >
                  {rewardsOptions?.type === 'Size' ? (
                    <Box display="flex" flexDirection="column">
                      <Box display="flex" marginBottom="24px">
                        <Typography
                          variant="h4"
                          className="justify-center"
                          alignContent="center"
                          marginRight="16px"
                        >
                          Size
                        </Typography>
                        <Chip
                          sx={{
                            color: green[900],
                            backgroundColor: green[100],
                          }}
                          className="!rounded-sm h-24"
                          label={
                            <Typography fontSize={'14px'} fontWeight="bold">
                              ACTIVE
                            </Typography>
                          }
                        />
                      </Box>

                      <Typography variant="body-3" className="w-full">
                        Sizes
                      </Typography>
                      <Typography variant="body-2" className="w-full">
                        {orderedValueArray.toString().split(',').join(', ') ??
                          []}
                      </Typography>
                    </Box>
                  ) : (
                    <Typography variant="body-3" className="w-full">
                      Edit to add options like sizing
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>

            <EditRewardOptionsModal
              isModalOpen={isOptionsModalOpen}
              toggleModalIsOpen={toggleOptionsModalIsOpen}
              reward={reward}
              rewardsOptions={rewardsOptions}
              rewardsOptionsValues={orderedValueArray}
            />
          </Box>
        )}
      </Box>
    </Page>
  )
}
