import { parse } from 'date-fns'
import { shouldValidateEndTime, validateEndTime } from 'utils/util'
import { bool, date, string } from 'yup'

export const rewardItemNameSchema = string()
  .required('This field requires entry')
  .max(100)

export const rewardItemDescriptionSchema = string().optional().max(500)

export const rewardItemTypeSchema = string().oneOf(
  ['JJStoreItem', 'MatchPlay', 'LocationOffer'],
  'Reward Type is not valid'
)

export const rewardItemSKUSchema = string()
  .optional()
  .matches(/^[\w-]*$/)
  .max(25)
  .typeError('Alphanumeric characters only')

export const rewardItemVendorItemNameSchema = string().optional().max(500)

export const rewardItemGiftCardSchema = bool()

export const rewardItemScheduledRewardItemSchema = string()

export const rewardItemStartDateSchema = date()
  .nullable()
  .typeError('This date is invalid')
  .transform((currentValue, originalValue, context) => {
    if (context.isType(currentValue)) {
      return currentValue
    }
    if (originalValue === '') {
      return null
    }
    if (originalValue === 'MM/DD/YYYY') {
      return null
    }
    try {
      parse(originalValue, 'MM/DD/YYYY', new Date())
    } catch (error) {
      return new Date('')
    }
    return currentValue
  })
  .when('scheduledRewardItem', {
    is: (scheduledRewardItem: string) => scheduledRewardItem === 'Yes',
    then: () =>
      date()
        .required('This field requires entry')
        .typeError('This field requires entry'),
  })

export const rewardItemStartTimeSchema = string().when('scheduledRewardItem', {
  is: (scheduledRewardItem: string) => scheduledRewardItem === 'Yes',
  then: () =>
    string()
      .required('This field requires entry')
      .matches(
        /^(0\d|1[0-2]):[0-5]\d [APap][mM]$/,
        'Invalid time format. Please enter a valid time.'
      ),
})

export const rewardItemEndDateSchema = date()
  .nullable()
  .typeError('This date is invalid')
  .transform((currentValue, originalValue, context) => {
    if (context.isType(currentValue)) {
      return currentValue
    }
    if (originalValue === '') {
      return null
    }
    if (originalValue === 'MM/DD/YYYY') {
      return null
    }
    try {
      parse(originalValue, 'MM/DD/YYYY', new Date())
    } catch (error) {
      return new Date('')
    }
    return currentValue
  })
  .when(
    ['scheduledRewardItem', 'startDate'],
    ([scheduledRewardItem, startDate], schema) => {
      if (
        scheduledRewardItem &&
        startDate instanceof Date &&
        isFinite(+startDate)
      ) {
        return schema.min(startDate, 'End date should not be before start date')
      }
      return schema
    }
  )

export const rewardItemEndTimeSchema = string().when(
  ['startDate', 'startTime', 'endDate'],
  ([startDate, startTime, endDate], schema) => {
    return schema.test({
      name: 'endTime',
      message: 'This field is required',
      test: (value) => {
        const timeRegex = /^(0?[1-9]|1[0-2]):[0-5]\d (AM|PM)$/
        if (
          value &&
          endDate instanceof Date &&
          isFinite(+endDate) &&
          !timeRegex.test(value)
        ) {
          return false
        }

        if (endDate instanceof Date && value?.toString().length === 0) {
          return false
        }

        if (value && endDate instanceof Date && isFinite(+endDate)) {
          shouldValidateEndTime(startDate, startTime, endDate, value) &&
            validateEndTime(startDate, startTime, endDate, value)
        }

        return true
      },
    })
  }
)
