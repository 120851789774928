/* eslint-disable no-template-curly-in-string */
import { isPresent } from '@jjvgaming/player-payback-library'
import { date, number, object, string } from 'yup'
import { Alert, Button, Grid, InputLabel } from '@mui/material'
import { TextField } from 'components/Shared/TextField'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { ActivityButton } from 'components/ActivityButton'
import { Modal } from 'components/Modal/Modal'
import { useLayout } from 'hooks/useLayout'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { type Campaign } from 'types/api'
import { type RequiredId } from 'utils/typeUtils'
import { AdornedDateTimeField } from 'components/AdornedDateTimeField/AdornedDateTimeField'
import { isNil } from 'lodash'
import { formatISO, isAfter, parseISO } from 'date-fns'
import { useEffect } from 'react'

interface EditCampaignDetailsProps {
  campaign: RequiredId<Campaign>
  isModalOpen: boolean
  toggleModalIsOpen: () => void
  updateIsActive: boolean
  onSubmitCampaign: (campaign: RequiredId<Campaign>) => void
}

export const EditCommunicationDetailsModal = ({
  campaign,
  updateIsActive,
  isModalOpen,
  toggleModalIsOpen,
  onSubmitCampaign,
}: EditCampaignDetailsProps) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : '75%'
  const deliveryDate = getDeliveryDate(campaign)

  const CampaignDetailsSchema = object().shape(
    {
      Campaign: object().shape({
        id: number(),
        subject: string()
          .required('Subject is a required field')
          .max(50, 'Subject cannot exceed 50 characters'),
        body: string()
          .required('Message Content is a required field')
          .max(500, 'Message Content cannot exceed 500 characters'),
        licensedEstablishmentId: number(),
        deliveryDate: date()
          .typeError('Must enter a valid Delivery Date')
          .required('Must enter a Delivery Date')
          .test(
            'DayInPast',
            'Scheduled Date should not be a date in the past',
            (value) => {
              return !isNil(deliveryDate) &&
                deliveryDate.toISOString() !== value.toISOString()
                ? isAfter(value, new Date())
                : true
            }
          ),
      }),
      linkLabel: string()
        .when('linkUrl', {
          is: (val: string) => val && val.length > 0,
          then: (schema) =>
            schema.required('Link Label is required if Link URL is present'),
          otherwise: (schema) => schema.notRequired(),
        })
        .max(30, 'Link label can be at most ${max} characters'),
      linkUrl: string()
        .when('linkLabel', {
          is: (val: string) => val && val.length > 0,
          then: (schema) =>
            schema.required('Link URL is required if Link Label is present'),
          otherwise: (schema) => schema.notRequired(),
        })
        .max(200, 'Link URL can be at most ${max} characters')
        .test('is-https', 'Link URL must start with "https://"', (value) => {
          // only check for https if value exists
          if (value && value.length > 0) {
            return value.startsWith('https://')
          }
          return true // if the value is empty, skip the test
        }),
    },
    [['linkLabel', 'linkUrl']]
  )

  const formMethods = useForm({
    resolver: yupResolver(CampaignDetailsSchema),
    mode: 'all',
    defaultValues: {
      Campaign: {
        deliveryDate: getDeliveryDate(campaign),
        body: campaign.body ? campaign.body : '',
        subject: campaign.subject ? campaign.subject : '',
      },
    },
  })

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods

  const onCancel = () => {
    toggleModalIsOpen()
    reset()
  }

  // use useWatch to watch the linkLabel and linkUrl fields without slowing down the text input fields
  const [linkLabel, linkUrl] = useWatch({
    control,
    name: ['linkLabel', 'linkUrl'],
  })

  useEffect(() => {
    if (!linkLabel && !linkUrl) {
      // clear linkLabel and linkUrl errors when both are empty
      formMethods.clearErrors(['linkLabel', 'linkUrl'])
    }
  }, [linkLabel, linkUrl, formMethods])

  const handleSubmitWrapper = handleSubmit((data) => {
    onSubmitCampaign({
      ...campaign,
      id: campaign.id,
      subject: data.Campaign.subject,
      body: data.Campaign.body,
      licensedEstablishments: campaign.licensedEstablishments,
      deliveryDate: formatISO(data.Campaign.deliveryDate),
      linkLabel: data.linkLabel,
      linkUrl: data.linkUrl,
    })
  })

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: isMobile ? '100%' : '55%',
        height: heightModal,
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitWrapper}>
          <ModalFullHeader
            title="Edit Message Details"
            subTitle={`${campaign.type}`}
            toggleIsOpen={toggleModalIsOpen}
          />
          <ModalFullBody heightModal={heightModal}>
            <Grid container>
              <Grid container>
                {Object.keys(errors).length > 0 && (
                  <Grid item xs={12} pb={4}>
                    <Alert severity="error">
                      Please correct errors to continue
                    </Alert>
                  </Grid>
                )}

                <Grid item xs={12} md={6} pt={{ md: 2 }}>
                  <InputLabel
                    required
                    htmlFor="Message.name"
                    sx={{ fontSize: '16px', fontWeight: '100', width: '100%' }}
                  >
                    Subject Line
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    required
                    error={isPresent(errors.Campaign?.subject)}
                    placeholder="Enter Subject Line"
                    {...register('Campaign.subject')}
                    defaultValue={campaign.subject}
                    helperText="50 charcters max"
                  />
                  {errors.Campaign?.subject?.message && (
                    <FormFieldErrorMessage
                      message={errors.Campaign.subject.message}
                    />
                  )}
                </Grid>
              </Grid>

              <Grid container pt={4}>
                <Grid item xs={12} md={6} pt={{ md: 2 }}>
                  <InputLabel
                    htmlFor="Message.body"
                    required
                    sx={{ fontSize: '16px', fontWeight: '100' }}
                  >
                    Message Content
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    multiline
                    rows={5}
                    required
                    error={isPresent(errors.Campaign?.body)}
                    placeholder="Enter description of Message"
                    {...register('Campaign.body')}
                    defaultValue={campaign.body}
                    sx={{ width: '100%' }}
                    helperText="500 charcters max"
                  />
                  {errors.Campaign?.body?.message && (
                    <FormFieldErrorMessage
                      message={errors.Campaign.body.message}
                    />
                  )}
                </Grid>
              </Grid>

              <Grid container pt={4}>
                <Grid item xs={12} md={6} pt={{ md: 2 }}>
                  <InputLabel
                    required={!!linkUrl} // display as required if linkUrl is present, remove requried if linkUrl is empty
                    sx={{ fontSize: '16px', fontWeight: '100' }}
                  >
                    Link Label
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    defaultValue={campaign.linkLabel}
                    placeholder="Enter a label for the link"
                    helperText="30 characters max"
                    error={
                      linkUrl
                        ? !!(errors.linkLabel?.message && linkUrl.length > 0) // display error if linkUrl is present, remove error if linkUrl is empty
                        : false
                    }
                    {...register('linkLabel', {
                      setValueAs: (value) => value || null, // save value as NULL instead of an empty string if field is empty
                    })}
                  />
                  {errors.linkLabel?.message && linkUrl && (
                    <FormFieldErrorMessage message={errors.linkLabel.message} /> // display error message if linkUrl is present and there is an error
                  )}
                </Grid>
              </Grid>

              <Grid container pt={4}>
                <Grid item xs={12} md={6} pt={{ md: 2 }}>
                  <InputLabel
                    required={!!linkLabel} // display as required if linkUrl is present, remove requried if linkUrl is empty
                    sx={{ fontSize: '16px', fontWeight: '100' }}
                  >
                    Link URL
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    defaultValue={campaign.linkUrl}
                    placeholder="https://www.example.com"
                    helperText="200 characters max"
                    error={
                      linkLabel
                        ? !!(errors.linkUrl?.message && linkLabel.length > 0) // display error if linkLabel is present, remove error if linkLabel is empty
                        : false
                    }
                    {...register('linkUrl', {
                      setValueAs: (value) => value || null, // save value as NULL instead of an empty string if field is empty
                    })}
                  />
                  {errors.linkUrl?.message && linkLabel && (
                    <FormFieldErrorMessage message={errors.linkUrl.message} /> // display error if linkLabel is present, remove error if linkLabel is empty
                  )}
                </Grid>
              </Grid>

              <Grid container pt={4}>
                <Grid item xs={12} md={6} pt={{ md: 2 }}>
                  <InputLabel
                    htmlFor="Campaign.deliveryDate"
                    required
                    sx={{ fontSize: '16px', fontWeight: '100' }}
                  >
                    Scheduled Date
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <AdornedDateTimeField
                    name="Campaign.deliveryDate"
                    control={control}
                  />
                  {errors.Campaign?.deliveryDate?.message && (
                    <FormFieldErrorMessage
                      message={errors.Campaign?.deliveryDate.message}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </ModalFullBody>
          <ModalFullFooter>
            <Button variant="text" onClick={() => onCancel()}>
              Cancel
            </Button>
            <ActivityButton
              disabled={
                !!errors.Campaign || !!errors.linkLabel || !!errors.linkUrl
              }
              active={!!updateIsActive}
              type="submit"
              variant="contained"
            >
              Save
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </FormProvider>
    </Modal>
  )
}

const getDeliveryDate = (campaign: Campaign) => {
  if (isNil(campaign.deliveryDate)) {
    return undefined
  }

  return parseISO(campaign.deliveryDate)
}
