import { isNil, startCase } from 'lodash'
import { useParams } from 'react-router-dom'
import { useGetPatronById } from 'hooks/api/Patron/useGetPatronById'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { format, parseISO } from 'date-fns'
import { PatronDetailsHeader } from '../DetailsHeader/PatronDetailsHeader'
import {
  Button,
  Divider,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { SectionHeader } from 'components/SectionHeader'
import { EditPatronInformation } from 'pages/Patrons/EditPatronInformation/EditPatronInformation'
import { useMemo, useState } from 'react'
import { FlagPatronAccountAsExcludedModal } from '../../ExcludePatron/FlagPatronAccountAsExcludedModal'
import { FlagPatronAccountAsDeactivatedModal } from 'pages/Patrons/DeactivatePatron/FlagPatronAccountAsDeactivatedModal'
import { getDeactivatedDate, getExcludedDate } from 'utils/util'
import { formatPhoneNumber } from 'utils/phoneUtils'
import { ActivityButton } from 'components/ActivityButton'
import { usePutPatronTier } from 'hooks/api/Patron/usePutPatronTier'
import { useSnackbar } from 'stores/useSnackbar'
import { useQueryClient } from '@tanstack/react-query'
import { useGetTiers } from 'hooks/api/useGetTiers'
import { Counter } from 'components/Counter/Counter'
import { useGetPatronCheckInsByRange } from 'hooks/api/Patron/useGetPatronCheckInsByRange'
import { usePostManualPatronCheckin } from 'hooks/api/Patron/usePostManualPatronCheckin'
import { type Tier } from 'types/api'
import { usePostManualPatronSweepstakeEntry } from 'hooks/api/Patron/usePostManualPatronSweepstakeEntry'
import { DropdownSweepstakes } from 'components/Shared/DropdownSweepstakes'
import { useDropdownSweepstakesStore } from 'stores/useDropdownSweepstakesStore'
import { useDropdownParticipatingLocationsStore } from 'stores/useDropdownParticipatingLocationStore'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { SelectParticipatingLocation } from 'components/SelectParticipatingLocations'

export const PatronOverviewDesktop = () => {
  const { id: patronAccountId } = useParams()
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedTier, setSelectedTier] = useState<number | null>(null)
  const [checkinsToGrant, setCheckinsToGrant] = useState(0)
  const { dropdownSweepstakesValue } = useDropdownSweepstakesStore()
  const { dropdownParticipatingLocationsValue } =
    useDropdownParticipatingLocationsStore()
  const [sweepstakeEntriesToGrant, setSweepstakeEntriesToGrant] = useState(0)
  const { data: patronCheckIns } = useGetPatronCheckInsByRange({
    patronId: Number(patronAccountId),
    range: 'CurrentQuarter',
  })

  const schema = yup.object({})

  const formMethods = useForm({
    resolver: yupResolver(schema),
  })

  const toggleEditModalIsOpen = async () => {
    setIsEditModalOpen(!isEditModalOpen)
  }
  const [
    isFlagPatronAccountAsExcludedModalOpen,
    setIsFlagPatronAccountAsExcludedModalOpen,
  ] = useState(false)
  const qc = useQueryClient()
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const tiersQuery = useGetTiers()
  const tiers = tiersQuery.data
  const selectedTierString = useMemo(() => {
    if (isNil(selectedTier)) {
      return ''
    }

    return String(selectedTier)
  }, [selectedTier])

  const toggleFlagPatronAccountAsExcludedModalIsOpen = async () => {
    setIsFlagPatronAccountAsExcludedModalOpen(
      !isFlagPatronAccountAsExcludedModalOpen
    )
  }

  const [
    isFlagPatronAccountAsDeactivatedModalOpen,
    setIsFlagPatronAccountAsDeactivatedModalOpen,
  ] = useState(false)
  const toggleFlagPatronAccountAsDeactivatedModalIsOpen = async () => {
    setIsFlagPatronAccountAsDeactivatedModalOpen(
      !isFlagPatronAccountAsDeactivatedModalOpen
    )
  }

  const patronQuery = useGetPatronById(Number(patronAccountId))

  const putPatronMutation = usePutPatronTier({
    onError: () => {
      setSnackbarMessage(
        'An error occurred while processing changed Patron Tier',
        'error'
      )
    },
    onSuccess: async () => {
      await qc.invalidateQueries({
        queryKey: ['/admin/patronaccount/detail'],
      })
      setSelectedTier(null)
      setSnackbarMessage('Successfully changed Patron Tier', 'success')
    },
  })

  const setCheckinsToGrantWithValidation = (amount: number) => {
    if (amount > 0) {
      setCheckinsToGrant(amount)
      return
    }

    if (Number(patronCheckIns?.length) + amount > 0) {
      setCheckinsToGrant(amount)
    }
  }

  const resetAll = () => {
    setCheckinsToGrant(0)
  }

  const { mutate: postManualPatronCheckinMutation } =
    usePostManualPatronCheckin({
      onSuccess: async () => {
        setSnackbarMessage(
          `Check-Ins successfully updated for ${patronQuery.data?.firstName} ${patronQuery.data?.lastName}.`,
          'success'
        )
        await qc.invalidateQueries({
          queryKey: [
            '/admin/patron/checkins/range',
            Number(patronAccountId),
            'CurrentQuarter',
          ],
        })
        resetAll()
      },
      onError: () => {
        setSnackbarMessage(
          `There was an error adding Check-Ins for ${patronQuery.data?.firstName} ${patronQuery.data?.lastName}.`,
          'error'
        )
      },
    })

  const setSweepstakeEntriesValidation = (amount: number) => {
    if (amount > 0) {
      setSweepstakeEntriesToGrant(amount)
      return
    }

    if (Number(patronCheckIns?.length) + amount > 0) {
      setSweepstakeEntriesToGrant(amount)
    }
  }

  const resetSweepstakeEntries = () => {
    setSweepstakeEntriesToGrant(0)
  }

  const { mutate: postManualPatronSweepstakeEntryMutation } =
    usePostManualPatronSweepstakeEntry({
      onSuccess: async () => {
        setSnackbarMessage(
          `Sweepstake Entries successfully updated for ${patronQuery.data?.firstName} ${patronQuery.data?.lastName}.`,
          'success'
        )
        await qc.invalidateQueries({
          queryKey: ['/admin/patron/SweepstakeEntries'],
        })
        resetSweepstakeEntries()
      },
      onError: () => {
        setSnackbarMessage(
          `There was an error adding Sweepstake Entries for ${patronQuery.data?.firstName} ${patronQuery.data?.lastName}.`,
          'error'
        )
      },
    })

  if (!patronAccountId) {
    return 'Patron Account Id cannot be null'
  }

  if (patronQuery.isPending) {
    return <ActivityIndicator />
  }

  if (patronQuery.isError || isNil(patronQuery.data)) {
    return <div>An error occurred.</div>
  }

  const createdOn = !isNil(patronQuery.data.createdOn)
    ? parseISO(patronQuery.data.createdOn)
    : null

  const dob = !isNil(patronQuery.data.dob)
    ? parseISO(patronQuery.data.dob)
    : null

  const gender = startCase(patronQuery.data?.gender)

  const mostRecentMailingAddress =
    !isNil(patronQuery.data?.addresses) && patronQuery.data.addresses.length > 0
      ? patronQuery.data?.addresses.sort((a, b) =>
          (a.id > b.id && -1) || a.id < b.id ? 1 : 0
        )[0]
      : null
  return (
    <PatronDetailsHeader
      patron={patronQuery.data}
      currentTab={`/PatronAccounts/${patronAccountId}/Overview`}
    >
      <div hidden={!patronQuery.data?.exclude}>
        <SectionHeader showButton={false} title="User Excluded" />
        <div className="flex flex-col border rounded p-6 mb-5">
          <div className="grid grid-cols-4 gap-4">
            <div>
              <Typography variant="body-1">
                <p className="break-words">{patronQuery.data?.excludeReason}</p>
              </Typography>
              <Typography variant="body-3">
                {getExcludedDate(patronQuery.data?.excludeDate)}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div hidden={!patronQuery.data?.deactivated}>
        <SectionHeader showButton={false} title="Patron Deactivated" />
        <div className="flex flex-col border rounded p-6 mb-5">
          <div className="grid grid-cols-4 gap-4">
            <div>
              <Typography variant="body-1" className="break-words">
                {patronQuery.data?.deactivatedReason}
              </Typography>
              <Typography variant="body-3">
                {getDeactivatedDate(patronQuery.data?.deactivatedOn)}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div hidden={!patronQuery.data?.pushNotificationsDisabled}>
        <SectionHeader showButton={false} title="Push Notifications Disabled" />
        <div className="flex flex-col border rounded p-6 mb-5">
          <div className="grid grid-cols-4 gap-4">
            <div>
              <Typography variant="body-1" className="break-words">
                Patron has disabled push notifications
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <SectionHeader
        title="Patron Account"
        onClickButton={toggleEditModalIsOpen}
      />
      <div className="flex flex-col border rounded p-6 mb-5">
        <div className="flex flex-col justify-center space-y-4">
          <Typography variant="h4" fontWeight={'bold'}>
            Patron ID
          </Typography>
          <Typography variant="body-1">#{patronQuery.data?.id}</Typography>
        </div>
        <Divider sx={{ marginY: '24px' }} orientation="horizontal" />
        <div className="flex flex-col justify-center space-y-4">
          <Typography variant="h4" fontWeight={'bold'}>
            Name
          </Typography>
          <div className="grid grid-cols-4 gap-4">
            <div>
              <Typography variant="body-3">First Name</Typography>
              <Typography variant="body-1">
                {patronQuery.data?.firstName}
              </Typography>
            </div>
            <div>
              <Typography variant="body-3">Last Name</Typography>
              <Typography variant="body-1">
                {patronQuery.data?.lastName}
              </Typography>
            </div>
          </div>
        </div>
        <Divider sx={{ marginY: '24px' }} orientation="horizontal" />
        <div className="flex flex-col justify-center space-y-4">
          <Typography variant="h4" fontWeight={'bold'}>
            Contact
          </Typography>
          <div className="grid grid-cols-4 gap-4">
            <div>
              <Typography variant="body-3">Phone Number</Typography>
              {!isNil(patronQuery.data.phoneNumber) && (
                <Typography variant="body-1">
                  {formatPhoneNumber(patronQuery.data.phoneNumber)}
                </Typography>
              )}
            </div>
            <div>
              <Typography variant="body-3">Email</Typography>
              <Typography variant="body-1">
                {patronQuery.data?.email}
              </Typography>
            </div>
          </div>
        </div>
        <Divider sx={{ marginY: '24px' }} orientation="horizontal" />
        <div className="flex flex-col justify-center space-y-4">
          <Typography variant="h4" fontWeight={'bold'}>
            Birthdate
          </Typography>
          {!isNil(dob) && (
            <Typography variant="body-1">{`${format(dob, 'P')}`}</Typography>
          )}
        </div>
        <Divider sx={{ marginY: '24px' }} orientation="horizontal" />
        <div className="flex flex-col justify-center space-y-4">
          <Typography variant="h4" fontWeight={'bold'}>
            Gender
          </Typography>
          <Typography variant="body-1">{gender}</Typography>
        </div>
        <Divider sx={{ marginY: '24px' }} orientation="horizontal" />
        <div className="flex flex-col justify-center space-y-4">
          <Typography variant="h4" fontWeight={'bold'}>
            Mailing Address
          </Typography>
          <div className="grid grid-cols-4 gap-4">
            <div>
              <Typography variant="body-3">Address Line 1</Typography>
              <Typography variant="body-1">
                {mostRecentMailingAddress?.address1 ?? '-'}
              </Typography>
            </div>
            <div>
              <Typography variant="body-3">Address Line 2</Typography>
              <Typography variant="body-1">
                {mostRecentMailingAddress?.address2 ?? '-'}
              </Typography>
            </div>
            <div>
              <Typography variant="body-3">City</Typography>
              <Typography variant="body-1">
                {mostRecentMailingAddress?.city ?? '-'}
              </Typography>
            </div>
          </div>
          <div className="grid grid-cols-4 gap-4">
            <div>
              <Typography variant="body-3">State</Typography>
              <Typography variant="body-1">
                {mostRecentMailingAddress?.state ?? '-'}
              </Typography>
            </div>
            <div>
              <Typography variant="body-3">Zip</Typography>
              <Typography variant="body-1">
                {mostRecentMailingAddress?.postalCode ?? '-'}
              </Typography>
            </div>
          </div>
        </div>
        <Divider sx={{ marginY: '24px' }} orientation="horizontal" />
        <div className="flex flex-col justify-center space-y-4">
          <Typography variant="h4" fontWeight={'bold'}>
            Member Since
          </Typography>
          {!isNil(createdOn) && (
            <Typography variant="body-1">
              {`${format(createdOn, 'P')} at ${format(createdOn, 'p')}`}
            </Typography>
          )}
        </div>
        <Divider sx={{ marginY: '24px' }} orientation="horizontal" />
        <div className="flex flex-col justify-center">
          <Typography variant="h4" fontWeight={'bold'}>
            Tier
          </Typography>

          <Stack
            direction={'row'}
            columnGap={10}
            rowGap={4}
            alignItems={'end'}
            flexWrap={'wrap'}
          >
            <Stack
              direction={'row'}
              gap={2}
              alignItems={'end'}
              flexWrap={'wrap'}
            >
              <Stack direction={'column'}>
                <Typography variant="body-1" mt={2}>
                  Current Tier:{' '}
                  {patronQuery.data.currentTier?.tier?.type ?? 'Tier Not Set'}
                </Typography>
                <Select
                  sx={{ minWidth: 150 }}
                  value={selectedTierString}
                  onChange={(event) =>
                    setSelectedTier(Number(event.target.value))
                  }
                >
                  {Array.isArray(tiers) &&
                    tiers?.map((tier: Tier) => (
                      <MenuItem
                        key={tier.id}
                        value={tier.id}
                        disabled={
                          tier.id === patronQuery.data.currentTier?.tier?.id
                        }
                      >
                        {tier.type}{' '}
                        {tier.id === patronQuery.data.currentTier?.tier?.id
                          ? '(Current)'
                          : ''}
                      </MenuItem>
                    ))}
                </Select>
              </Stack>
              <ActivityButton
                variant="contained"
                disabled={isNil(selectedTier)}
                active={putPatronMutation.isPending}
                onClick={async () => {
                  if (isNil(selectedTier)) {
                    throw new Error("Selected Tier can't be null")
                  }
                  await putPatronMutation.mutateAsync({
                    id: patronQuery.data.id,
                    tierId: selectedTier,
                  })
                }}
              >
                Update Tier
              </ActivityButton>
            </Stack>

            <Stack
              direction={'row'}
              alignItems={'end'}
              columnGap={0}
              rowGap={2}
              flexWrap={'wrap'}
            >
              <Stack direction={'column'}>
                <Typography variant="body-1" mt={2}>
                  Current Check-in Count: {patronCheckIns?.length}
                </Typography>
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                  <Counter
                    amount={checkinsToGrant}
                    setAmount={setCheckinsToGrantWithValidation}
                    minimumAmount={0}
                  />
                </Stack>
              </Stack>
              <ActivityButton
                variant="contained"
                disabled={checkinsToGrant === 0}
                active={putPatronMutation.isPending}
                onClick={() => {
                  postManualPatronCheckinMutation({
                    patronId: Number(patronAccountId),
                    numCheckins: checkinsToGrant,
                  })
                }}
              >
                Update Check-in Count
              </ActivityButton>
            </Stack>
          </Stack>

          <Typography variant="body-1" mt={2}>
            Patron Entries into a Selected Sweepstake
          </Typography>
          <Stack direction={'row'}>
            <Stack direction={'column'} rowGap={1}>
              <Stack>
                <DropdownSweepstakes liveOnly={true} />
                <form>
                  <FormProvider {...formMethods}>
                    <SelectParticipatingLocation
                      placeholder="Select Location"
                      contestId={Number(dropdownSweepstakesValue)}
                    />
                  </FormProvider>
                </form>
              </Stack>
              <Stack direction={'column'} gap={2} alignItems={'center'}>
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                  <Counter
                    amount={sweepstakeEntriesToGrant}
                    setAmount={setSweepstakeEntriesValidation}
                    minimumAmount={0}
                  />
                  <ActivityButton
                    variant="contained"
                    disabled={
                      sweepstakeEntriesToGrant === 0 ||
                      dropdownSweepstakesValue === '' ||
                      dropdownParticipatingLocationsValue === ''
                    }
                    active={putPatronMutation.isPending}
                    onClick={() => {
                      postManualPatronSweepstakeEntryMutation({
                        patronId: Number(patronAccountId),
                        sweepstakeId: Number(dropdownSweepstakesValue),
                        licensedEstablishmentId: Number(
                          dropdownParticipatingLocationsValue
                        ),
                        numSweepstakeEntries: Number(sweepstakeEntriesToGrant),
                        patronCheckInId: null,
                      })
                    }}
                  >
                    Update
                  </ActivityButton>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </div>
      </div>
      <div>
        <Button
          onClick={() => {
            setIsFlagPatronAccountAsExcludedModalOpen(
              !isFlagPatronAccountAsExcludedModalOpen
            )
          }}
        >
          {!patronQuery.data.exclude
            ? 'Flag Patron Account as Excluded'
            : 'Unflag Patron Account to Include'}
        </Button>
      </div>
      <Button
        onClick={() => {
          setIsFlagPatronAccountAsDeactivatedModalOpen(
            !isFlagPatronAccountAsDeactivatedModalOpen
          )
        }}
      >
        {!patronQuery.data.deactivated
          ? 'Deactivate Patron'
          : 'Reactivate Patron'}
      </Button>
      <EditPatronInformation
        isModalOpen={isEditModalOpen}
        toggleModalIsOpen={toggleEditModalIsOpen}
        patron={patronQuery.data}
      />
      <FlagPatronAccountAsExcludedModal
        isModalOpen={isFlagPatronAccountAsExcludedModalOpen}
        toggleModalIsOpen={toggleFlagPatronAccountAsExcludedModalIsOpen}
        toggleIsOpen={toggleFlagPatronAccountAsExcludedModalIsOpen}
        patron={patronQuery.data}
      />

      <FlagPatronAccountAsDeactivatedModal
        isModalOpen={isFlagPatronAccountAsDeactivatedModalOpen}
        toggleModalIsOpen={toggleFlagPatronAccountAsDeactivatedModalIsOpen}
        toggleIsOpen={toggleFlagPatronAccountAsDeactivatedModalIsOpen}
        patron={patronQuery.data}
      />
    </PatronDetailsHeader>
  )
}
