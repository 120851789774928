import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { isNil } from 'lodash'
import { Page } from 'components/Shared/Page'
import { isIdPresent } from 'utils/typeUtils'
import { CommunicationDetailsHeader } from './CommunicationDetailsHeader'
import { useGetCampaignDetailsById } from 'hooks/api/useGetCampaignDetailsById'
import { SectionHeader } from 'components/SectionHeader'
import { EditCommunicationDetailsModal } from './EditCommunicationDetailsModal'
import { useState } from 'react'
import { usePutCampaign } from 'hooks/api/usePutCampaign'
import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'stores/useSnackbar'
import { formatDateTime } from 'utils/util'
import { ViewLocationsModal } from './ViewLocationsModal'
import { useGetPatronMessageCountsByCampaignId } from 'hooks/api/useGetPatronMessageCountsByCampaignId'

export const CommunicationDetailsPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isViewLocationsModalOpen, setIsViewLocationsModalOpen] =
    useState(false)
  const toggleModalIsOpen = () => setIsModalOpen(!isModalOpen)
  const toggleViewLocationsModalIsOpen = () =>
    setIsViewLocationsModalOpen(!isViewLocationsModalOpen)
  const id = Number(useParams().id)
  const getCampaignQuery = useGetCampaignDetailsById({ id })
  const getPatronMessageCountQuery = useGetPatronMessageCountsByCampaignId({
    id,
  })
  const theme = useTheme()
  const queryClient = useQueryClient()
  const setMessage = useSnackbar((state) => state.setMessage)

  const name =
    getCampaignQuery.data?.corporateAccount?.name ??
    getCampaignQuery.data?.contest?.name ??
    Number(getCampaignQuery.data?.licensedEstablishments?.length) > 0

  const campaignType = getCampaignQuery.data?.type
  const totalCount = getPatronMessageCountQuery.data?.totalCount
  const sentCount = getPatronMessageCountQuery.data?.sentCount
  const openedCount = getPatronMessageCountQuery.data?.openedCount
  const percentOptedIn = getPatronMessageCountQuery.data?.percentOptedIn

  const nameType =
    (campaignType === 'Sweepstakes' && 'Sweepstakes Name') ||
    (campaignType === 'Corporate' && 'Corporate Account Name') ||
    (campaignType === 'Location' && 'Locations')

  const details = [
    name,
    getCampaignQuery.data?.tier,
    getCampaignQuery.data?.excludedOrganization,
    getCampaignQuery.data?.excludedCorporateAccount,
    getCampaignQuery.data?.excludedLicensedEstablishment,
  ].find(Boolean)

  const putCampaignMutation = usePutCampaign({
    id,
    onSuccess: () => {
      setMessage('Campaign has been updated.', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      void queryClient.invalidateQueries({
        queryKey: ['/admin/campaigns/details/{id}', campaign.id],
      })
      toggleModalIsOpen()
    },
    onError: () => {
      setMessage('Error updating Message', 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  if (getCampaignQuery.isPending) {
    return <CircularProgress />
  }

  if (getCampaignQuery.isError) {
    return <p>An error occurred.</p>
  }

  const campaign = getCampaignQuery.data

  if (isNil(campaign) || !isIdPresent(campaign)) {
    return <p>An error occurred.</p>
  }

  return (
    <Page header={<CommunicationDetailsHeader message={campaign} />}>
      <Box className={'pt-4'}>
        <Box className="pt-4">
          <Box>
            <SectionHeader
              title="Communication Message"
              onClickButton={toggleModalIsOpen}
              showButton={campaign.status !== 'Sent'}
            />
            <Box
              border={{ md: `1px solid ${theme.palette.grey[200]}` }}
              borderRadius={'5px'}
              p={{ xs: 0, sm: 3 }}
            >
              <Grid
                container
                className="flex flex-row flex-wrap"
                columnGap={15}
              >
                <Grid item md={2}>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    Date Scheduled
                  </Typography>
                  <Typography variant="body1" pb={2}>
                    {campaign.deliveryDate
                      ? formatDateTime(campaign.deliveryDate, 'P p')
                      : 'None'}
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    Subject Line
                  </Typography>
                  <Typography height="auto" variant="body1" pb={2}>
                    {campaign.subject ?? ''}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                className="flex flex-row flex-wrap"
                columnGap={15}
              >
                <Grid item md={8}>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    Message Content
                  </Typography>
                  <Typography height="auto" variant="body1" pb={2}>
                    {campaign.body ?? ''}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                className="flex flex-row flex-wrap"
                columnGap={15}
              >
                <Grid item md={2}>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    Link Label
                  </Typography>
                  <Typography variant="body1" pb={2}>
                    {campaign.linkLabel ?? ''}
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    Link URL
                  </Typography>
                  <Typography height="auto" variant="body1" pb={2}>
                    {campaign.linkUrl ?? ''}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        {/* Details Section */}
        {details && (
          <Box className="pt-4">
            <Box>
              <SectionHeader title="Communication Details" showButton={false} />
              <Box
                border={{ md: `1px solid ${theme.palette.grey[200]}` }}
                borderRadius={'5px'}
                p={{ xs: 0, sm: 3 }}
              >
                <Grid
                  container
                  className="flex flex-row flex-wrap"
                  columnGap={15}
                >
                  {name && (
                    <Grid item md={2}>
                      <Typography variant="body2" pb={1} color="text.secondary">
                        {nameType}
                      </Typography>
                      {nameType === 'Locations' ? (
                        <Box display="flex" alignItems="flex-end">
                          <Button
                            variant="contained"
                            onClick={() => setIsViewLocationsModalOpen(true)}
                          >
                            View Locations
                          </Button>
                          <Typography style={{ marginLeft: '10px' }}>
                            {`${
                              getCampaignQuery.data?.licensedEstablishments
                                ? String(
                                    getCampaignQuery.data
                                      ?.licensedEstablishments.length
                                  )
                                : '0'
                            }
                          Location(s)`}
                          </Typography>
                        </Box>
                      ) : (
                        <Typography variant="body1" pb={2}>
                          {name}
                        </Typography>
                      )}
                    </Grid>
                  )}
                  {getCampaignQuery.data.tier && (
                    <Grid item md={2}>
                      <Typography variant="body2" pb={1} color="text.secondary">
                        Tier
                      </Typography>
                      <Typography height="auto" variant="body1" pb={2}>
                        {getCampaignQuery.data.tier.type}
                      </Typography>
                    </Grid>
                  )}
                </Grid>

                <Grid
                  container
                  className="flex flex-row flex-wrap"
                  columnGap={15}
                >
                  {getCampaignQuery.data.excludedOrganization && (
                    <Grid item md={2}>
                      <Typography variant="body2" pb={1} color="text.secondary">
                        Excluded Organization
                      </Typography>
                      <Typography height="auto" variant="body1" pb={2}>
                        {getCampaignQuery.data.excludedOrganization.name}
                      </Typography>
                    </Grid>
                  )}
                  {getCampaignQuery.data.excludedCorporateAccount && (
                    <Grid item md={2}>
                      <Typography variant="body2" pb={1} color="text.secondary">
                        Excluded Corporate Account
                      </Typography>
                      <Typography height="auto" variant="body1" pb={2}>
                        {getCampaignQuery.data.excludedCorporateAccount.name}
                      </Typography>
                    </Grid>
                  )}
                  {getCampaignQuery.data.excludedLicensedEstablishment && (
                    <Grid item md={2}>
                      <Typography variant="body2" pb={1} color="text.secondary">
                        Excluded Licensed Establishment
                      </Typography>
                      <Typography height="auto" variant="body1" pb={2}>
                        {
                          getCampaignQuery.data.excludedLicensedEstablishment
                            .name
                        }
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          </Box>
        )}

        <Box className="pt-4">
          <Box>
            <SectionHeader title="Communication Reports" showButton={false} />
            <Box
              border={{ md: `1px solid ${theme.palette.grey[200]}` }}
              borderRadius={'5px'}
              p={{ xs: 0, sm: 3 }}
            >
              <Grid
                container
                className="flex flex-row flex-wrap"
                columnGap={15}
              >
                <Grid item md={2}>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    Total Count
                  </Typography>
                  <Typography variant="body1" pb={2}>
                    {totalCount}
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    Sent Count
                  </Typography>
                  <Typography variant="body1" pb={2}>
                    {sentCount}
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    Opened Count
                  </Typography>
                  <Typography height="auto" variant="body1" pb={2}>
                    {openedCount}
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    Percent Opted into Push Notifications
                  </Typography>
                  <Typography height="auto" variant="body1" pb={2}>
                    {`${percentOptedIn}%`}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>

        {/* Filters Section */}
        <Box className="pt-4">
          <Box>
            <SectionHeader title="Communication Filters" showButton={false} />
            <Box
              border={{ md: `1px solid ${theme.palette.grey[200]}` }}
              borderRadius={'5px'}
              p={{ xs: 0, sm: 3 }}
            >
              <Grid
                container
                className="flex flex-row flex-wrap"
                columnGap={15}
              >
                <Grid item md={2}>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    Gender
                  </Typography>
                  <Typography variant="body1" pb={2}>
                    {campaign.filterGender}
                  </Typography>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    Age Range
                  </Typography>
                  <Typography variant="body1" pb={2}>
                    {campaign.filterAgeRange}
                  </Typography>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    State(s)
                  </Typography>
                  <Typography variant="body1" pb={2}>
                    {campaign.filterState}
                  </Typography>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    Zip Code(s)
                  </Typography>
                  <Typography variant="body1" pb={2}>
                    {campaign.filterZipCode}
                  </Typography>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    Incomplete Profiles
                  </Typography>
                  <Typography variant="body1" pb={2}>
                    {campaign.filterIncompleteProfiles ? 'True' : 'False'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                className="flex flex-row flex-wrap"
                columnGap={15}
              >
                {getCampaignQuery.data.excludedOrganization && (
                  <Grid item md={2}>
                    <Typography variant="body2" pb={1} color="text.secondary">
                      Excluded Organization
                    </Typography>
                    <Typography height="auto" variant="body1" pb={2}>
                      {getCampaignQuery.data.excludedOrganization.name}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>

      <EditCommunicationDetailsModal
        campaign={campaign}
        updateIsActive={putCampaignMutation.isPending}
        isModalOpen={isModalOpen}
        toggleModalIsOpen={toggleModalIsOpen}
        onSubmitCampaign={(campaign) => {
          putCampaignMutation.mutate(campaign)
        }}
      />

      <ViewLocationsModal
        isModalOpen={isViewLocationsModalOpen}
        toggleModalIsOpen={toggleViewLocationsModalIsOpen}
        campaign={campaign}
      />
    </Page>
  )
}
