import { useState } from 'react'
import { FilterList } from '@mui/icons-material'
import {
  Divider,
  Typography,
  Radio,
  Menu,
  MenuItem,
  Box,
  Button,
} from '@mui/material'
import { type SweepstakeWinnerStatusType } from 'types/api'

export const statusTextMap: Record<SweepstakeWinnerStatusType, string> = {
  LocationValidation: 'Location Validation',
  RedrawRequest: 'Redraw Requested',
  WinnerValidation: 'Winner Validated',
  WinnerNotified: 'Winner Notified',
  AffidavitReceived: 'Affidavit Received',
  Awarded: 'Awarded',
  SentToAP: 'Sent To AP',
  NoWinner: 'No Winner',
  GrandPrizeValidation: 'Grand Prize Validation',
}

export const FilterStatusList = ({
  buttonName,
  listName,
  statusFilter,
  setStatusFilter,
}: {
  buttonName: string
  listName: string
  statusFilter: string | null
  setStatusFilter: (value: SweepstakeWinnerStatusType | null) => void
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleToggle = (value: SweepstakeWinnerStatusType) => () => {
    if (statusFilter === value) {
      setStatusFilter(null)
    } else {
      setStatusFilter(value)
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-menu' : undefined

  return (
    <div>
      <Button aria-describedby={id} color="primary" onClick={handleClick}>
        <Typography sx={{ mr: '5px' }} color="primary" variant="label-CTA">
          {buttonName}
        </Typography>
        <FilterList />
      </Button>
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ padding: 1 }}>
          <Typography variant="label-CTA">{listName}</Typography>
        </Box>
        <Divider />

        {Object.entries(statusTextMap).map(([key, value]) => {
          const labelId = `radio-list-secondary-label-${key}`
          return (
            <MenuItem key={key}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Typography variant="body-2">{value}</Typography>
                <Radio
                  edge="end"
                  checked={statusFilter === key}
                  onChange={handleToggle(key as SweepstakeWinnerStatusType)}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </Box>
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}
