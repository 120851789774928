import { isPresent } from '@jjvgaming/player-payback-library'
import { type EditPromotionDetailsProps } from './types'
import { object } from 'yup'
import { formatISO, parse, parseISO } from 'date-fns'
import {
  Alert,
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import EventIcon from '@mui/icons-material/Event'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { HookableDateField } from 'components/Shared/HookableDateField'
import { HookableTimeField } from 'components/Shared/HookableTimeField'
import { useState } from 'react'
import { ActivityButton } from 'components/ActivityButton'
import { SelectOrganization } from 'components/SelectOrganization'
import {
  promotionDescriptionSchema,
  promotionEndDateSchema,
  promotionEndTimeSchema,
  promotionLicensedEstablishmentArraySchema,
  promotionCorporateAccountIdSchema,
  promotionOrganizationIdSchema,
  promotionNameSchema,
  promotionScheduledPromotionSchema,
  promotionStartDateSchema,
  promotionStartTimeSchema,
  promotionTypeSchema,
  promotionLinkLabelSchema,
  promotionLinkURLSchema,
} from 'utils/schemas/promotionSchema'
import { Modal } from 'components/Modal/Modal'
import { useLayout } from 'hooks/useLayout'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { SelectCorporateAccount } from 'components/SelectCorporateAccount'
import { LocationSelect } from '../LocationSelect'

export const PromotionDetailsSchema = object().shape({
  name: promotionNameSchema,
  description: promotionDescriptionSchema,
  type: promotionTypeSchema,
  licensedEstablishments: promotionLicensedEstablishmentArraySchema,
  corporateAccountId: promotionCorporateAccountIdSchema,
  organizationId: promotionOrganizationIdSchema,
  scheduledPromotion: promotionScheduledPromotionSchema,
  startDate: promotionStartDateSchema,
  startTime: promotionStartTimeSchema,
  endDate: promotionEndDateSchema,
  endTime: promotionEndTimeSchema,
  linkLabel: promotionLinkLabelSchema,
  linkURL: promotionLinkURLSchema,
})

export const EditPromotionDetails = ({
  promotion,
  updateIsActive,
  isModalOpen,
  toggleModalIsOpen,
  onSubmitPromotion,
}: EditPromotionDetailsProps) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : '75%'

  const getDefaultType = (description: string | undefined) => {
    if (description === 'Global') return 'J&J Promotion'
    else if (description === 'Location') return 'Location Promotion'
    else if (description === 'CorporateAccount') {
      return 'Corporate Account Promotion'
    } else if (description === 'Organization') return 'Organization Promotion'
  }

  const getType = (description: string) => {
    if (description === 'J&J Promotion') return 'Global'
    else if (description === 'Location Promotion') return 'Location'
    else if (description === 'Corporate Account Promotion') {
      return 'CorporateAccount'
    } else if (description === 'Organization Promotion') return 'Organization'
  }

  const [selectedPromotionType, setSelectedPromotionType] = useState(
    getDefaultType(promotion.type)
  )
  const [selectedScheduledPromotion, setSelectedScheduledPromotion] = useState(
    promotion.startDate ? 'Yes' : 'No'
  )
  const formMethods = useForm({
    resolver: yupResolver(PromotionDetailsSchema),
  })

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
  } = formMethods

  const handleSubmitWrapper = handleSubmit((data) => {
    onSubmitPromotion({
      ...promotion,
      title: data.name,
      description: data.description,
      type: getType(data.type),
      startDate:
        data.scheduledPromotion === 'Yes'
          ? data.startDate && data.startTime
            ? formatISO(parse(data.startTime, 'hh:mm a', data.startDate))
            : null
          : null,
      endDate:
        data.scheduledPromotion === 'Yes'
          ? data.endDate && data.endTime
            ? formatISO(parse(data.endTime, 'hh:mm a', data.endDate))
            : null
          : null,
      licensedEstablishments:
        data.licensedEstablishments && data.licensedEstablishments?.length > 0
          ? data.licensedEstablishments
          : null,
      corporateAccountId:
        data.corporateAccountId !== 0 ? data.corporateAccountId : null,
      organizationId: data.organizationId !== 0 ? data.organizationId : null,
      linkLabel: data.linkLabel !== '' ? data.linkLabel : null,
      linkURL: data.linkURL !== '' ? data.linkURL : null,
    })
  })

  const handleTypeChange = (e: SelectChangeEvent) => {
    if (e.target.value === 'Location Promotion') {
      register('licensedEstablishments')
    } else {
      unregister('licensedEstablishments')
    }
    if (e.target.value === 'Corporate Account Promotion') {
      register('corporateAccountId')
    } else {
      unregister('corporateAccountId')
    }
    if (e.target.value === 'Organization Promotion') {
      register('organizationId')
    } else {
      unregister('organizationId')
    }
    setSelectedPromotionType(e.target.value)
  }

  const defaultStartDate = promotion.startDate
    ? parseISO(promotion.startDate)
    : null

  const defaultEndDate = promotion.endDate ? parseISO(promotion.endDate) : null

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: isMobile ? '100%' : '55%',
        height: heightModal,
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitWrapper} noValidate>
          <ModalFullHeader
            title="Special & Event Details"
            subTitle={`Special & Event "${
              (promotion as { title: string }).title
            }"`}
            toggleIsOpen={toggleModalIsOpen}
          />
          <ModalFullBody heightModal={heightModal}>
            <Grid container>
              <Grid container>
                {Object.keys(errors).length > 0 && (
                  <Grid item xs={12} pb={4}>
                    <Alert severity="error">
                      Please correct errors to continue
                    </Alert>
                  </Grid>
                )}

                <Grid item xs={12} md={6} pt={{ md: 2 }}>
                  <InputLabel
                    required
                    htmlFor="name"
                    sx={{ fontSize: '16px', fontWeight: '100', width: '100%' }}
                  >
                    Special & Event Title
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    required
                    error={isPresent(errors.name)}
                    placeholder="Enter Title"
                    defaultValue={promotion?.title}
                    {...register('name')}
                  />
                  {errors.name?.message && (
                    <FormFieldErrorMessage message={errors.name.message} />
                  )}
                </Grid>
              </Grid>

              <Grid container pt={4}>
                <Grid item xs={12} md={6} pt={{ md: 2 }}>
                  <InputLabel
                    htmlFor="description"
                    required
                    sx={{ fontSize: '16px', fontWeight: '100' }}
                  >
                    Special & Event Description
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    multiline
                    rows={5}
                    required
                    error={isPresent(errors.description)}
                    placeholder="Enter description of promotion"
                    {...register('description')}
                    defaultValue={promotion.description}
                    sx={{ width: '100%' }}
                  />
                  <Grid item xs={12} md={6}>
                    <Typography variant="body-3">500 characters max</Typography>
                  </Grid>
                  {errors.description?.message && (
                    <FormFieldErrorMessage
                      message={errors.description.message}
                    />
                  )}
                </Grid>
              </Grid>

              <Grid container pt={4}>
                <Grid item xs={12} md={6} pt={{ md: 2 }}>
                  <InputLabel
                    htmlFor="type"
                    required
                    sx={{ fontSize: '16px', fontWeight: '100' }}
                  >
                    Special & Event Type
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    {...register('type', { required: true })}
                    inputProps={{ id: 'promotionType' }}
                    error={isPresent(errors.type)}
                    defaultValue={getDefaultType(promotion.type)}
                    value={selectedPromotionType}
                    sx={{ width: '100%', maxWidth: '520px' }}
                    onChange={handleTypeChange}
                  >
                    <MenuItem value="Location Promotion">
                      Location Promotion
                    </MenuItem>
                    <MenuItem value="Corporate Account Promotion">
                      Corporate Account Promotion
                    </MenuItem>
                    <MenuItem value="Organization Promotion">
                      Organization Promotion
                    </MenuItem>
                    <MenuItem value="J&J Promotion">J&J Promotion</MenuItem>
                  </Select>
                </Grid>
              </Grid>

              {selectedPromotionType === 'Location Promotion' && (
                <Grid container pt={4}>
                  <Grid item xs={12} md={6} pt={{ md: 2 }}>
                    <InputLabel
                      htmlFor="licensedEstablishments"
                      required
                      sx={{ fontSize: '16px', fontWeight: '100' }}
                    >
                      Licensed Establishments
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <LocationSelect
                      defaultPromotionId={promotion.id}
                      defaultLocations={promotion.licensedEstablishments ?? []}
                      showLabel={false}
                      {...register('licensedEstablishments', {
                        required: true,
                      })}
                    />
                    {errors.licensedEstablishments?.message && (
                      <FormFieldErrorMessage
                        message={errors.licensedEstablishments.message}
                      />
                    )}
                  </Grid>
                </Grid>
              )}

              {selectedPromotionType === 'Corporate Account Promotion' && (
                <Grid container pt={4}>
                  <Grid item xs={12} md={6} pt={{ md: 2 }}>
                    <InputLabel
                      htmlFor="corporateAccountId"
                      required
                      sx={{ fontSize: '16px', fontWeight: '100' }}
                    >
                      Corporate Account
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SelectCorporateAccount
                      {...register('corporateAccountId', {
                        required: false,
                      })}
                      inputProps={{ id: 'corporateAccountId' }}
                      defaultValue={
                        promotion.type === 'CorporateAccount'
                          ? promotion.corporateAccountId
                          : null
                      }
                      name="corporateAccountId"
                      fullWidth
                      corporateAccountIdKey="corporateAccountId"
                      placeholder="Select a corporate account"
                    />
                    {errors.corporateAccountId?.message && (
                      <FormFieldErrorMessage
                        message={errors.corporateAccountId.message}
                      />
                    )}
                  </Grid>
                </Grid>
              )}

              {selectedPromotionType === 'Organization Promotion' && (
                <Grid container pt={4}>
                  <Grid item xs={12} md={6} pt={{ md: 2 }}>
                    <InputLabel
                      htmlFor="organizationId"
                      required
                      sx={{ fontSize: '16px', fontWeight: '100' }}
                    >
                      Organization
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <SelectOrganization
                      fullWidth
                      label=""
                      defaultValue={
                        promotion.type === 'Organization'
                          ? promotion.organizationId
                          : null
                      }
                      required={false}
                      corporateAccountId={undefined}
                      showAll={true}
                      {...register('organizationId')}
                    />
                    {errors.organizationId?.message && (
                      <FormFieldErrorMessage
                        message={errors.organizationId.message}
                      />
                    )}
                  </Grid>
                </Grid>
              )}

              <Grid container pt={4}>
                <Grid item xs={12} md={6} pt={{ md: 2 }}>
                  <InputLabel
                    htmlFor="LicensedEstablishment.scheduledPromotion"
                    required
                    sx={{ fontSize: '16px', fontWeight: '100' }}
                  >
                    Do you want to schedule this Special & Event?
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    {...register('scheduledPromotion', {
                      required: true,
                    })}
                    inputProps={{ id: 'scheduledBoolean' }}
                    data-testid="scheduledBoolean"
                    error={isPresent(errors.scheduledPromotion)}
                    value={selectedScheduledPromotion}
                    onChange={(e) =>
                      setSelectedScheduledPromotion(e.target.value)
                    }
                    sx={{ width: '100%', maxWidth: '520px' }}
                  >
                    <MenuItem value="No">No</MenuItem>
                    <MenuItem value="Yes">Yes</MenuItem>
                  </Select>
                  {errors.scheduledPromotion?.message && (
                    <FormFieldErrorMessage
                      message={errors.scheduledPromotion.message}
                    />
                  )}
                </Grid>
              </Grid>

              {selectedScheduledPromotion === 'Yes' && (
                <Grid container pt={4}>
                  <Grid item xs={12} md={6} pt={{ md: 2 }}>
                    <InputLabel
                      htmlFor="startDate"
                      required
                      sx={{ fontSize: '16px', fontWeight: '100' }}
                    >
                      Start Date/Time
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={2.45}>
                    <HookableDateField
                      slotProps={{
                        textField: {
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <EventIcon />
                              </InputAdornment>
                            ),
                          },
                          error: isPresent(errors.startDate),
                        },
                      }}
                      defaultValue={defaultStartDate}
                      {...register('startDate')}
                    />
                    {errors.startDate?.message && (
                      <FormFieldErrorMessage
                        message={errors.startDate.message}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={0.2}></Grid>
                  <Grid item xs={12} md={2.45}>
                    <HookableTimeField
                      defaultValue={defaultStartDate as string | null}
                      {...register('startTime')}
                      slotProps={{
                        textField: {
                          required: true,
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccessTimeIcon />
                              </InputAdornment>
                            ),
                          },
                          error: isPresent(errors.startTime),
                        },
                      }}
                    />
                    {errors.startTime?.message && (
                      <FormFieldErrorMessage
                        message={errors.startTime.message}
                      />
                    )}
                  </Grid>
                </Grid>
              )}

              {selectedScheduledPromotion === 'Yes' && (
                <Grid container pt={4}>
                  <Grid item xs={12} md={6} pt={{ md: 2 }}>
                    <InputLabel
                      htmlFor="endDate"
                      sx={{ fontSize: '16px', fontWeight: '100' }}
                    >
                      End Date/Time
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={2.45}>
                    <HookableDateField
                      defaultValue={defaultEndDate}
                      sx={{ width: '100%' }}
                      slotProps={{
                        textField: {
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <EventIcon />
                              </InputAdornment>
                            ),
                          },
                          error: isPresent(errors.endDate),
                        },
                      }}
                      {...register('endDate')}
                    />
                    {errors.endDate?.message && (
                      <FormFieldErrorMessage message={errors.endDate.message} />
                    )}
                  </Grid>
                  <Grid item xs={12} md={0.2}></Grid>
                  <Grid item xs={12} md={2.45}>
                    <HookableTimeField
                      {...register('endTime')}
                      defaultValue={defaultEndDate as string | null}
                      slotProps={{
                        textField: {
                          required: true,
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccessTimeIcon />
                              </InputAdornment>
                            ),
                          },
                          error: isPresent(errors.endTime),
                        },
                      }}
                      sx={{ width: '100%' }}
                    />
                    {errors.endTime?.message && (
                      <FormFieldErrorMessage message={errors.endTime.message} />
                    )}
                  </Grid>
                </Grid>
              )}
              <Grid container pt={4}>
                <Grid item xs={12} md={6} pt={{ md: 2 }}>
                  <InputLabel
                    htmlFor="linkLabel"
                    sx={{ fontSize: '16px', fontWeight: '100', width: '100%' }}
                  >
                    Link Label
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    error={isPresent(errors.linkLabel)}
                    placeholder="Enter Link Label"
                    defaultValue={promotion?.linkLabel}
                    {...register('linkLabel')}
                  />
                  {errors.linkLabel?.message && (
                    <FormFieldErrorMessage message={errors.linkLabel.message} />
                  )}
                  <Typography fontSize={'12px'} color={'GrayText'}>
                    If Link Label is filled out, Link URL must be filled out
                  </Typography>
                </Grid>
              </Grid>
              <Grid container pt={1}>
                <Grid item xs={12} md={6} pt={{ md: 2 }}>
                  <InputLabel
                    htmlFor="linkURL"
                    sx={{ fontSize: '16px', fontWeight: '100', width: '100%' }}
                  >
                    Link URL
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    error={isPresent(errors.linkURL)}
                    placeholder="Enter Link URL"
                    defaultValue={promotion?.linkURL}
                    {...register('linkURL')}
                  />
                  {errors.linkURL?.message && (
                    <FormFieldErrorMessage message={errors.linkURL.message} />
                  )}
                  <Typography fontSize={'12px'} color={'GrayText'}>
                    If Link URL is filled out, Link Label must be filled out
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </ModalFullBody>
          <ModalFullFooter>
            <Button variant="text" onClick={toggleModalIsOpen}>
              Cancel
            </Button>
            <ActivityButton
              active={updateIsActive}
              type="submit"
              variant="contained"
            >
              Save
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </FormProvider>
    </Modal>
  )
}
