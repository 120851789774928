import { yupResolver } from '@hookform/resolvers/yup'
import { isPresent } from '@jjvgaming/player-payback-library'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import { TextField } from 'components/Shared/TextField'
import { useMutation } from '@tanstack/react-query'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { HookableDateField } from 'components/Shared/HookableDateField'
import { HookableTimeField } from 'components/Shared/HookableTimeField'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { formatISO, parse } from 'date-fns'
import { useApiClient } from 'hooks/useApiClient'
import { type FieldValues, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'stores/useSnackbar'
import {
  shouldValidateEndTime,
  shouldValidateStartTime,
  validateEndTime,
  validateStartTime,
} from 'utils/util'
import { date, object, string } from 'yup'

const today = new Date()
today.setHours(0, 0, 0, 0)

const passportGameSchema = object().shape({
  name: string().required('Must enter a passport name'),
  startDate: date()
    .typeError('Must enter a valid start date')
    .required('Must enter a start date')
    .min(today, 'Start date should not be a date in the past'),
  startTime: string()
    .required('Must enter a start time')
    .when(['startDate'], ([startDate], schema) => {
      return schema.test({
        name: 'startTime',
        message: 'Start time should not be in the past.',
        test: (value) =>
          shouldValidateStartTime(startDate, value)
            ? validateStartTime(startDate, value)
            : true,
      })
    }),
  endDate: date()
    .typeError('Must enter a valid end date')
    .required('Must enter an end date')
    .when(['startDate'], ([startDate], schema) => {
      if (startDate instanceof Date && isFinite(+startDate)) {
        return schema.min(startDate, 'End date should not be before start date')
      }
      return schema
    }),
  endTime: string()
    .required('Must enter end time')
    .when(
      ['startDate', 'startTime', 'endDate'],
      ([startDate, startTime, endDate], schema) => {
        return schema.test({
          name: 'endTime',
          message: 'End time should be after start time',
          test: (value) =>
            shouldValidateEndTime(startDate, startTime, endDate, value)
              ? validateEndTime(startDate, startTime, endDate, value)
              : true,
        })
      }
    ),

  description: string().required('Must enter a passport description').max(500),
})

export const PassportGameNewPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(passportGameSchema),
  })
  const { post } = useApiClient()
  const setMessage = useSnackbar((state) => state.setMessage)
  const navigate = useNavigate()

  const createPassportGame = useMutation({
    mutationFn: async (data: FieldValues) => {
      await post('/admin/contests', {
        params: { header: undefined },
        body: {
          name: data.name,
          grandPrizeType: data.grandPrizeType,
          description: data.description,
          type: 'PassportGame',
          startDate: formatISO(
            parse(data.startTime, 'hh:mm a', data.startDate)
          ),
          endDate: formatISO(parse(data.endTime, 'hh:mm a', data.endDate)),
        },
      })
    },
    onSuccess: async () => {
      setMessage('Passport Game successfully created', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      navigate('/Contests/PassportGames')
    },
    onError: async () => {
      setMessage('Passport Game not created', 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  const createPassportGameWrapper = handleSubmit((data) => {
    createPassportGame.mutate(data)
  })

  return (
    <Page
      header={
        <PageHeader
          title="Create a New Passport Game"
          backPath="/Contests/PassportGames"
          backText="Passport Games"
          isSecondary={true}
          subtitle={`You must fill out every field in the form before clicking the submit button.\nIncomplete submissions cannot be processed.`}
        />
      }
    >
      <form onSubmit={createPassportGameWrapper}>
        <Stack sx={{ maxWidth: '500px' }} spacing={{ xs: 4, sm: 5 }}>
          <Box>
            <TextField
              sx={{
                margin: '0',
                padding: '0',
              }}
              required
              error={isPresent(errors.name)}
              label="Passport Game Name"
              placeholder="Enter passport game name"
              {...register('name')}
            />
            {errors.name?.message && (
              <FormFieldErrorMessage message={errors.name.message} />
            )}
          </Box>
          <Grid container sx={{ display: 'flex', maxWidth: '500px' }}>
            <Grid item xs={6}>
              <HookableDateField
                required
                fullWidth
                label="Start Date"
                slotProps={{
                  textField: {
                    placeholder: 'mm/dd/yyyy',
                    error: isPresent(errors.startDate),
                  },
                }}
                sx={{ paddingRight: '24px' }}
                {...register('startDate', {
                  valueAsDate: true,
                })}
              />
              {errors.startDate?.message && (
                <FormFieldErrorMessage message={errors.startDate.message} />
              )}
            </Grid>
            <Grid item xs={6}>
              <HookableTimeField
                label="Start Time"
                required
                fullWidth
                slotProps={{
                  textField: {
                    placeholder: 'hh:mm AM/PM',
                    error: isPresent(errors.startTime),
                  },
                }}
                {...register('startTime')}
              />
              {errors.startTime?.message && (
                <FormFieldErrorMessage message={errors.startTime.message} />
              )}
            </Grid>
          </Grid>
          <Grid container sx={{ display: 'flex' }}>
            <Grid item xs={6}>
              <HookableDateField
                {...register('endDate', { valueAsDate: true })}
                label="End Date"
                slotProps={{
                  textField: {
                    placeholder: 'mm/dd/yyyy',
                    error: isPresent(errors.endDate),
                  },
                }}
                required
                sx={{ paddingRight: '24px' }}
              />
              {errors.endDate?.message && (
                <FormFieldErrorMessage message={errors.endDate.message} />
              )}
            </Grid>
            <Grid item xs={6}>
              <HookableTimeField
                label="End Time"
                required
                slotProps={{
                  textField: {
                    placeholder: 'hh:mm AM/PM',
                    error: isPresent(errors.endTime),
                  },
                }}
                {...register('endTime')}
              />
              {errors.endTime?.message && (
                <FormFieldErrorMessage message={errors.endTime.message} />
              )}
            </Grid>
          </Grid>
          <Box>
            <TextField
              multiline
              rows={3}
              required
              error={isPresent(errors.description)}
              label="Passport Game Description"
              placeholder="Enter description of passport game"
              {...register('description')}
            />
            <Typography variant="body-3" component={'p'} mt={1}>
              500 characters max
            </Typography>
            {errors.description?.message && (
              <FormFieldErrorMessage message={errors.description.message} />
            )}
          </Box>
          <Stack
            sx={{ marginTop: { xs: '500px' } }}
            direction="row"
            spacing={2}
          >
            <Button
              type="submit"
              variant="contained"
              disabled={createPassportGame.isPending}
            >
              Submit
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                navigate('/Rewards/RewardItems')
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </form>
    </Page>
  )
}
