import { yupResolver } from '@hookform/resolvers/yup'
import { object } from 'yup'
import { type FieldValues, useForm, FormProvider } from 'react-hook-form'
import { isPresent } from '@jjvgaming/player-payback-library'
import { useApiClient } from 'hooks/useApiClient'
import { useSnackbar } from 'stores/useSnackbar'
import { useNavigate, Link as RouterLink } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import {
  Alert,
  Box,
  Button,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { TextField } from 'components/Shared/TextField'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { useState } from 'react'
import { useLayout } from 'hooks/useLayout'
import EventIcon from '@mui/icons-material/Event'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { HookableDateField } from 'components/Shared/HookableDateField'
import { HookableTimeField } from 'components/Shared/HookableTimeField'
import { formatISO, parse } from 'date-fns'
import {
  rewardItemEndDateSchema,
  rewardItemEndTimeSchema,
  rewardItemNameSchema,
  rewardItemScheduledRewardItemSchema,
  rewardItemStartDateSchema,
  rewardItemStartTimeSchema,
} from 'utils/schemas/RewardItemSchema'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'

export const RewardItemSchema = object().shape({
  name: rewardItemNameSchema,
  scheduledRewardItem: rewardItemScheduledRewardItemSchema,
  startDate: rewardItemStartDateSchema,
  startTime: rewardItemStartTimeSchema,
  endDate: rewardItemEndDateSchema,
  endTime: rewardItemEndTimeSchema,
})

export const RewardItemsNewPage = () => {
  const formMethods = useForm({
    resolver: yupResolver(RewardItemSchema),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods

  const { post } = useApiClient()
  const setMessage = useSnackbar((state) => state.setMessage)
  const [selectedScheduledRewardItem, setSelectedScheduledRewardItem] =
    useState('No')
  const navigate = useNavigate()
  const { isMobile } = useLayout()

  const createRewardItem = useMutation({
    mutationKey: ['rewards/create'],
    mutationFn: async (data: FieldValues) => {
      const returnedData = await post('/admin/rewards', {
        params: { header: undefined },
        body: {
          name: data.name,
          type: 'LocationOffer',
          description: data.description,
          startDate: data.startDate
            ? formatISO(parse(data.startTime, 'hh:mm a', data.startDate))
            : null,
          endDate: data.endDate
            ? formatISO(parse(data.endTime, 'hh:mm a', data.endDate))
            : null,
        },
      })
      return returnedData
    },
    onSuccess: async (returnedData) => {
      setMessage('Reward Item was successfully created.', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      navigate(`/Rewards/RewardItems/${returnedData.data?.id}`)
    },
    onError: async () => {
      setMessage('Error creating reward item', 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  if (createRewardItem.isPending) {
    return <ActivityIndicator />
  }

  if (createRewardItem.isError) {
    return <h1>An error occured.</h1>
  }

  const createRewardItemWrapper = handleSubmit((data) => {
    createRewardItem.mutate(data)
  })

  return (
    <Page
      header={
        <PageHeader
          title={'Create a New Reward Item'}
          subtitle={
            'You must fill out every field in the form before clicking the submit button.\nIncomplete submissions cannot be processed.'
          }
          backText="Reward Items"
          backPath="/Rewards/RewardItems"
          isSecondary={true}
        ></PageHeader>
      }
    >
      <FormProvider {...formMethods}>
        <form onSubmit={createRewardItemWrapper} noValidate>
          <Grid item xs={12} pb={1}>
            {Object.keys(errors).length > 0 && (
              <Alert severity="error">Please correct errors to continue</Alert>
            )}
          </Grid>
          <Stack spacing={4} maxWidth={isMobile ? '90vw' : 500}>
            <Box>
              <TextField
                id="reward-item-name-text-box"
                required
                error={isPresent(errors.name)}
                label="Reward Item Name"
                placeholder="Enter reward item name"
                {...register('name')}
              />
              {errors.name?.message && (
                <FormFieldErrorMessage message={errors.name.message} />
              )}
            </Box>

            <Stack direction="column" gap={1}>
              <InputLabel
                htmlFor={'scheduledBoolean'}
                sx={isMobile ? { fontSize: '16px' } : {}}
              >
                Do you want to schedule this Reward Item?
              </InputLabel>
              <Select
                {...register('scheduledRewardItem', { required: true })}
                inputProps={{ id: 'scheduledBoolean' }}
                data-testid="scheduledBoolean"
                error={isPresent(errors.scheduledRewardItem)}
                defaultValue="No"
                value={selectedScheduledRewardItem}
                onChange={(e) => setSelectedScheduledRewardItem(e.target.value)}
              >
                <MenuItem value="No">No</MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
              </Select>
              {errors.scheduledRewardItem?.message && (
                <FormFieldErrorMessage
                  message={errors.scheduledRewardItem.message}
                />
              )}
            </Stack>
            {selectedScheduledRewardItem === 'Yes' && (
              <div>
                <Typography variant="body-2">
                  To keep the reward item active, don&apos;t select an end date
                  and time.
                </Typography>
                <div>
                  <Stack direction={'row'} spacing={2} marginTop={'15px'}>
                    <div style={{ flex: 1, width: '100%' }}>
                      <InputLabel
                        required
                        sx={isMobile ? { fontSize: '16px' } : {}}
                      >
                        Start Date
                      </InputLabel>
                      <HookableDateField
                        sx={{ width: '100%' }}
                        slotProps={{
                          textField: {
                            InputProps: {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <EventIcon />
                                </InputAdornment>
                              ),
                            },
                            error: isPresent(errors.startDate),
                          },
                        }}
                        {...register('startDate')}
                      />
                      {errors.startDate?.message && (
                        <FormFieldErrorMessage
                          message={errors.startDate.message}
                        />
                      )}
                    </div>
                    <div style={{ flex: 1, width: '100%' }}>
                      <InputLabel
                        required
                        sx={isMobile ? { fontSize: '16px' } : {}}
                      >
                        Start Time
                      </InputLabel>
                      <HookableTimeField
                        {...register('startTime')}
                        slotProps={{
                          textField: {
                            required: true,
                            InputProps: {
                              endAdornment: (
                                <InputAdornment position="end">
                                  <AccessTimeIcon />
                                </InputAdornment>
                              ),
                            },
                            error: isPresent(errors.startTime),
                          },
                        }}
                        sx={{ width: '100%' }}
                      />
                      {errors.startTime?.message && (
                        <FormFieldErrorMessage
                          message={errors.startTime.message}
                        />
                      )}
                    </div>
                  </Stack>
                </div>
                <Stack direction={'row'} spacing={2} marginTop={'15px'}>
                  <div style={{ flex: 1, width: '100%' }}>
                    <InputLabel sx={isMobile ? { fontSize: '16px' } : {}}>
                      End Date
                    </InputLabel>
                    <HookableDateField
                      sx={{ width: '100%' }}
                      slotProps={{
                        textField: {
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <EventIcon />
                              </InputAdornment>
                            ),
                          },
                          error: isPresent(errors.endDate),
                        },
                      }}
                      {...register('endDate')}
                    />
                    {errors.endDate?.message && (
                      <FormFieldErrorMessage message={errors.endDate.message} />
                    )}
                  </div>
                  <div style={{ flex: 1, width: '100%' }}>
                    <InputLabel sx={isMobile ? { fontSize: '16px' } : {}}>
                      End Time
                    </InputLabel>
                    <HookableTimeField
                      {...register('endTime')}
                      slotProps={{
                        textField: {
                          required: true,
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccessTimeIcon />
                              </InputAdornment>
                            ),
                          },
                          error: isPresent(errors.endTime),
                        },
                      }}
                      sx={{ width: '100%' }}
                    />
                    {errors.endTime?.message && (
                      <FormFieldErrorMessage message={errors.endTime.message} />
                    )}
                  </div>
                </Stack>
              </div>
            )}
            {isMobile && (
              <Grid container spacing={2} maxWidth="90vw">
                <Grid
                  item
                  xs={6}
                  style={{ paddingLeft: 0, paddingRight: '8px' }}
                >
                  <RouterLink to="/Rewards/RewardItems">
                    <Button variant="outlined" fullWidth>
                      Cancel
                    </Button>
                  </RouterLink>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ paddingLeft: '8px', paddingRight: 0 }}
                >
                  <Button type="submit" variant="contained" fullWidth>
                    Create
                  </Button>
                </Grid>
              </Grid>
            )}
            {!isMobile && (
              <Stack direction="row" spacing={2}>
                <RouterLink to="/Rewards/RewardItems">
                  <Button variant="outlined">Cancel</Button>
                </RouterLink>
                <Button type="submit" variant="contained">
                  Create
                </Button>
              </Stack>
            )}
          </Stack>
        </form>
      </FormProvider>
    </Page>
  )
}
