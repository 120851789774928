import { useState } from 'react'
import { formatDateTime } from 'utils/util'
import { type Sweepstake } from 'types/api'
import { useSnackbar } from 'stores/useSnackbar'
import { type RequiredId } from 'utils/typeUtils'
import { Box, Grid, Typography } from '@mui/material'
import { EditSweepstakeDetails } from './EditSweepstakeDetails'
import { SectionHeader } from 'components/SectionHeader'
import { usePutSweepstake } from 'hooks/api/Sweepstake/usePutSweepstake'

export interface EditSweepstakeDetailsProps {
  sweepstake: RequiredId<Sweepstake>
  onSubmitSweepstake: (sweepstake: RequiredId<Sweepstake>) => void
  toggleModalIsOpen: () => void
  updateIsActive: boolean
  isModalOpen: boolean
}

export const SweepstakesDetailsSection = ({
  sweepstake,
}: {
  sweepstake: Sweepstake
}) => {
  const setMessage = useSnackbar((state) => state.setMessage)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleModalIsOpen = () => setIsModalOpen(!isModalOpen)

  const putSweepstakeMutation = usePutSweepstake({
    sweepstakeId: sweepstake.id,
    onSuccess: () => {
      setMessage('Sweepstake has been updated.', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      toggleModalIsOpen()
    },
    onError: () => {
      setMessage('Error updating Sweepstake', 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  return (
    <>
      <SectionHeader
        title="Sweepstake Overview"
        onClickButton={toggleModalIsOpen}
      />
      <Box
        sx={{
          borderWidth: 1,
          borderColor: 'grey.300',
          borderRadius: 2,
          p: '16px',
          mb: '40px',
        }}
      >
        <Grid container sx={{ rowGap: 5 }}>
          <Grid item md={4}>
            <Typography variant="body-2" color="text.secondary">
              Sweepstake Name
            </Typography>
            <Typography variant="body-1" color="text.primary">
              {sweepstake.name}
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography variant="body-2" color="text.secondary">
              Grand Prize Type
            </Typography>
            <Typography variant="body-1" color="text.primary">
              {sweepstake.grandPrizeType
                .replace(/([A-Z])/g, ' $1')
                .replace(/^ +/, '')}
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography variant="body-2" color="text.secondary">
              Start Date
            </Typography>
            <Typography variant="body-1" color="text.primary">
              {formatDateTime(sweepstake.startDate, 'P p')}
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography variant="body-2" color="text.secondary">
              End Date
            </Typography>
            <Typography variant="body-1" color="text.primary">
              {formatDateTime(sweepstake.endDate, 'P p')}
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography variant="body-2" color="text.secondary">
              Number of Grand Prize Winners Per Tier
            </Typography>
            <Typography variant="body-1" color="text.primary">
              {sweepstake.sweepstakeData?.numGrandPrizeWinnersPerTier ?? '-'}
            </Typography>
          </Grid>
          <Grid item md={8}>
            <Typography variant="body-2" color="text.secondary">
              URL
            </Typography>
            <Typography variant="body-1" color="text.primary">
              {sweepstake.url ?? '-'}
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Typography variant="body-2" color="text.secondary">
              Sweepstake Description
            </Typography>
            <Typography variant="body-1" color="text.primary">
              {sweepstake.description}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <EditSweepstakeDetails
        sweepstake={sweepstake}
        updateIsActive={putSweepstakeMutation.isPending}
        isModalOpen={isModalOpen}
        onSubmitSweepstake={(sweepstake) => {
          putSweepstakeMutation.mutate(sweepstake)
        }}
        toggleModalIsOpen={toggleModalIsOpen}
      />
    </>
  )
}
