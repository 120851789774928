import { type DefaultValues } from 'react-hook-form'

export interface JJConnectFormFields {
  id?: number
  email: string
  firstName: string
  lastName: string
  corporateAccountId?: number
  smartId?: number | null
}

export const defaultValues: DefaultValues<JJConnectFormFields> = {
  id: undefined,
  email: '',
  firstName: '',
  lastName: '',
  corporateAccountId: undefined,
  smartId: undefined,
}
