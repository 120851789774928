import { useQuery } from '@tanstack/react-query'
import { ActivityIndicator } from './Shared/ActivityIndicator'
import { Box, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import { type ForwardedRef, forwardRef } from 'react'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { isNil } from 'lodash'

interface SelectOrganizationProps {
  fullWidth: boolean
  label: string | undefined
  name: string | undefined
  corporateAccountId: number | undefined
  showAll: boolean | undefined
  required: boolean | undefined
  defaultValue?: number | null
}

export const SelectOrganization = forwardRef(
  (
    {
      label,
      fullWidth,
      name,
      corporateAccountId,
      required,
      showAll,
      defaultValue,
      ...otherProps
    }: SelectOrganizationProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const get = showAll
      ? useTypedApiClient({
          path: '/admin/organizations',
          method: 'get',
        })
      : useTypedApiClient({
          path: '/admin/organizations/corporate-accounts/{id}',
          method: 'get',
        })
    const {
      isPending,
      isError,
      data: organizations,
    } = useQuery({
      queryKey: showAll
        ? ['organizations/list']
        : ['corporateAccounts/organizations', corporateAccountId],

      queryFn: async () => {
        try {
          const { data } = showAll
            ? await get({ id: 0 })
            : isNil(corporateAccountId)
              ? { data: [] }
              : await get({
                  id: corporateAccountId,
                })
          return data
        } catch (e) {
          return []
        }
      },
    })

    if (isPending) {
      return <ActivityIndicator />
    }

    if (isError) {
      return <Typography>Error</Typography>
    }

    return (
      <Box>
        <InputLabel htmlFor={name} required={required}>
          {label}
        </InputLabel>
        <Select
          fullWidth={fullWidth}
          labelId={name}
          name={name}
          ref={ref}
          variant="outlined"
          defaultValue={defaultValue ?? null}
          required={required}
          inputProps={{ id: name }}
          {...otherProps}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {organizations?.map((org) => (
            <MenuItem key={org.id} value={org.id}>
              {org.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    )
  }
)

SelectOrganization.displayName = 'SelectOrganization'
