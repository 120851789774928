import { useState } from 'react'
import Box from '@mui/material/Box'
import { type Promotion } from 'types/api'
import { formatDateTime } from 'utils/util'
import { useSnackbar } from 'stores/useSnackbar'
import { type RequiredId } from 'utils/typeUtils'
import { useQueryClient } from '@tanstack/react-query'
import { SectionHeader } from 'components/SectionHeader'
import { Button, Grid, Typography, useTheme } from '@mui/material'
import { EditPromotionDetails } from './EditPromotionDetails'
import { usePutPromotion } from 'hooks/api/Promotion/usePutPromotion'
import { ViewLocationsModal } from 'pages/Communications/CommunicationDetailPage/ViewLocationsModal'

interface PromotionDetailsProps {
  promotion: RequiredId<Promotion>
}

export const PromotionDetailsDesktop = ({
  promotion,
}: PromotionDetailsProps) => {
  const setMessage = useSnackbar((state) => state.setMessage)
  const theme = useTheme()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModalIsOpen = () => setIsModalOpen(!isModalOpen)
  const [isViewLocationsModalOpen, setIsViewLocationsModalOpen] =
    useState(false)
  const toggleViewLocationsModalIsOpen = () =>
    setIsViewLocationsModalOpen(!isViewLocationsModalOpen)
  const queryClient = useQueryClient()

  const promotionType = () => {
    if (promotion.type === 'Global') {
      return `J&J Promo`
    } else if (
      promotion.licensedEstablishments &&
      promotion.licensedEstablishments.length > 0
    ) {
      return `Location Specific`
    } else if (promotion.corporateAccount) {
      return `Corporate Account Specific`
    } else if (promotion.organization) {
      return `Organization Specific`
    } else return '-'
  }

  const putPromotionMutation = usePutPromotion({
    promotionId: promotion.id,
    onSuccess: () => {
      setMessage('Promotion has been updated.', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      void queryClient.invalidateQueries({
        queryKey: ['/promotions', promotion.id],
      })
      toggleModalIsOpen()
    },
    onError: () => {
      setMessage('Error updating Promotion', 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  return (
    <>
      <Box className={'pt-4'}>
        <Box className="pt-4">
          <Box>
            <SectionHeader
              title="Special & Event Details"
              onClickButton={toggleModalIsOpen}
            />
            <Box
              border={{ md: `1px solid ${theme.palette.grey[200]}` }}
              borderRadius={'5px'}
              p={{ xs: 0, sm: 3 }}
            >
              <Grid
                container
                className="flex flex-row flex-wrap"
                columnGap={15}
              >
                <Grid item md={2}>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    Special & Event Name
                  </Typography>
                  <Typography variant="body1" pb={2}>
                    {promotion.title}
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    Start Date
                  </Typography>
                  <Typography variant="body1" pb={2}>
                    {promotion.startDate
                      ? formatDateTime(promotion.startDate, 'P p')
                      : 'None'}
                  </Typography>
                </Grid>
                <Grid item md={2}>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    End Date
                  </Typography>
                  <Typography variant="body1" pb={2}>
                    {promotion.endDate
                      ? formatDateTime(promotion.endDate, 'P p')
                      : 'None'}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                className="flex flex-row flex-wrap"
                columnGap={15}
              >
                <Grid item md={2}>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    Special & Event Type
                  </Typography>
                  <Typography variant="body1" pb={2}>
                    {promotionType()}
                  </Typography>
                </Grid>
                {promotion.type === 'Location' && (
                  <Grid item md={2}>
                    <Typography variant="body2" pb={1} color="text.secondary">
                      LE Name
                    </Typography>
                    <Box display="flex" alignItems="flex-end">
                      <Button
                        variant="contained"
                        onClick={() => setIsViewLocationsModalOpen(true)}
                      >
                        View Locations
                      </Button>
                      <Typography style={{ marginLeft: '10px' }}>
                        {`${promotion.licensedEstablishments?.length} Location(s)`}
                      </Typography>
                    </Box>
                  </Grid>
                )}
                {promotion.type === 'CorporateAccount' && (
                  <Grid item md={2}>
                    <Typography variant="body2" pb={1} color="text.secondary">
                      Corporate Account
                    </Typography>
                    <Typography variant="body1" pb={2}>
                      {promotion.corporateAccount?.name}
                    </Typography>
                  </Grid>
                )}
                {promotion.type === 'Organization' && (
                  <Grid item md={2}>
                    <Typography variant="body2" pb={1} color="text.secondary">
                      Organization
                    </Typography>
                    <Typography variant="body1" pb={2}>
                      {promotion.organization?.name}
                    </Typography>
                  </Grid>
                )}
              </Grid>

              <Grid
                container
                className="flex flex-row flex-wrap"
                columnGap={15}
              >
                <Grid item md={8}>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    Description
                  </Typography>
                  <Typography variant="body1" pb={2}>
                    {promotion.description ?? ''}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                className="flex flex-row flex-wrap"
                columnGap={15}
              >
                <Grid item md={8}>
                  <Typography variant="body2" pb={1} color="text.secondary">
                    Link
                  </Typography>
                  <Typography variant="body1">
                    {promotion.linkLabel ?? '-'}
                  </Typography>
                  <Typography variant="body1">
                    {promotion.linkURL ?? ''}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>

      <EditPromotionDetails
        promotion={promotion}
        updateIsActive={putPromotionMutation.isPending}
        isModalOpen={isModalOpen}
        toggleModalIsOpen={toggleModalIsOpen}
        onSubmitPromotion={(promotion) => {
          putPromotionMutation.mutate(promotion)
        }}
      />

      <ViewLocationsModal
        isModalOpen={isViewLocationsModalOpen}
        toggleModalIsOpen={toggleViewLocationsModalIsOpen}
        promotion={promotion}
      />
    </>
  )
}
