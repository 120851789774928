import { type UseQueryResult } from '@tanstack/react-query'
import { useApiQuery } from 'hooks/useApiQuery'
import { type RewardsRedemption } from 'types/api'

export const useGetRewardsRedemptionsByTransactionDate = ({
  startDate,
  endDate,
}: {
  startDate?: string
  endDate?: string
  onSuccess?: (response: RewardsRedemption[]) => void
}): UseQueryResult<{
  startTransactionDate?: string
  endTransactionDate?: string
}> => {
  return useApiQuery({
    path: '/admin/rewards/redemptions/transaction-date',
    method: 'get',
    queryKey: [
      '/admin/redemptions/rewards/rewards-programs/rewardsProgramId/redemptions',
      startDate,
      endDate,
    ],
    queryArgs: {
      startTransactionDate: startDate,
      endTransactionDate: endDate,
    },
    enabled: !!startDate && !!endDate,
  })
}
