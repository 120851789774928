import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { ActivityButton } from 'components/ActivityButton'
import { usePutCheckInPolicy } from 'hooks/api/usePutCheckInPolicy'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'stores/useSnackbar'
import {
  type CheckInPolicy,
  type CheckInPolicyWelcomeBackMilestonePointBonusScheduleDTO,
} from 'types/api'

interface DeleteWelcomeBackMilestoneBonusModalProps {
  isModalOpen: boolean
  data?: CheckInPolicyWelcomeBackMilestonePointBonusScheduleDTO | null
  checkInPolicy?: CheckInPolicy | null
  enablePatronWelcomeBackMilestoneBonus: boolean
  welcomeBackMilestoneBonuschedulesData?: any[] | null
  toggleIsOpen: () => void
}

export const DeleteWelcomeBackMilestoneBonusModal = ({
  isModalOpen,
  toggleIsOpen,
  data,
  checkInPolicy,
  enablePatronWelcomeBackMilestoneBonus,
  welcomeBackMilestoneBonuschedulesData,
}: DeleteWelcomeBackMilestoneBonusModalProps) => {
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const params = useParams()
  const rewardsProgramId = Number(params.id)
  const formMethods = useForm()

  const { handleSubmit } = formMethods

  const useUpdateCheckInPolicyMutation = usePutCheckInPolicy({
    onSuccess: () => {
      setSnackbarMessage(
        `Welcome Back Milestone Bonus Schedule ID#${data?.id} successfully deleted`,
        'success'
      )
      toggleIsOpen()
    },
    onError: () => {
      setSnackbarMessage(
        `An error has occured while deleting the Welcome Back Milestone Bonus Schedule ID#${data?.id}`,
        'error'
      )
      toggleIsOpen()
    },
  })

  const handleSubmitWrapper = handleSubmit(() => {
    const updatedSchedules = welcomeBackMilestoneBonuschedulesData?.filter(
      (schedule) => {
        return schedule.id !== data?.id
      }
    )

    useUpdateCheckInPolicyMutation.mutate({
      ...checkInPolicy,
      enablePatronWelcomeBackMilestoneBonus,
      id: data?.checkInPolicyId ?? 0,
      rewardsProgramId,
      checkInPolicyWelcomeBackMilestonePointBonusSchedules: updatedSchedules,
    })
  })

  return (
    <Dialog
      open={isModalOpen}
      maxWidth={'xl'}
      fullWidth
      PaperProps={{ sx: { p: 0, maxWidth: '900px' } }}
      onClose={toggleIsOpen}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitWrapper} noValidate>
          <DialogTitle bgcolor={grey[50]} sx={{ p: 0 }} className="border-b">
            <Box px={4} py={4}>
              <Typography
                data-testid={'delete-welcome-back-milestone-bonus-modal'}
                variant="h3"
                fontWeight={'bold'}
              >
                Delete Welcome Back Milestone Bonus Schedule
              </Typography>
              <Typography variant="body-1" color={'text.secondary'}>
                ID#{data?.id}
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ pt: 4, px: 4 }}>
            <Box paddingBottom={2} paddingTop={3}>
              <Typography variant="h4" fontSize={18} paddingBottom={2}>
                {`Are you sure you want to delete this Welcome Back Milestone Bonus
            Schedule?`}
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={6}
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <InputLabel
                  sx={{ fontSize: 16, paddingTop: 2, fontWeight: 'normal' }}
                >
                  Point Bonus
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  value={data?.pointBonus}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <InputLabel
                  sx={{ fontSize: 16, paddingTop: 2, fontWeight: 'normal' }}
                >
                  Number of Weeks
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  margin="normal"
                  value={data?.patronCheckInLatestNumWeeks}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{ borderTop: `1px solid ${grey[300]}`, pt: 2, pb: 2, px: 4 }}
          >
            <Button variant="text" onClick={toggleIsOpen}>
              Cancel
            </Button>
            <ActivityButton
              type="submit"
              variant="contained"
              active={useUpdateCheckInPolicyMutation.isPending}
            >
              Delete Schedule
            </ActivityButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  )
}
