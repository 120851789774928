import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type CheckInPolicy, type FullCheckInPolicy } from 'types/api'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface UsePostCheckInPolicyProps {
  onSuccess?: (data: FullCheckInPolicy, variables: CheckInPolicy) => void
  onError?: (error: Error, variables: CheckInPolicy) => void
}

export const UsePostCheckInPolicy = ({
  onSuccess,
  onError,
}: UsePostCheckInPolicyProps) => {
  const post = useTypedApiClient({
    path: '/admin/policies/checkins',
    method: 'post',
  })
  const queryClient = useQueryClient()

  return useMutation<any, Error, CheckInPolicy>({
    mutationFn: async (data: CheckInPolicy) => {
      const response = await post({ ...data })
      return response.data
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/policies/checkins'],
      })
      await queryClient.invalidateQueries({
        queryKey: ['/admin/policies/checkins/rewards-programs'],
      })
      onSuccess?.(data, variables)
    },
    onError,
  })
}
