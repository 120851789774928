import ListBullet from 'assets/format_list_bulleted.svg'
import { Menu, MenuItem, IconButton } from '@mui/material'
import { type GridRenderCellParams } from '@mui/x-data-grid'
import { useState } from 'react'
import { type Sweepstake } from 'types/api'
import { useSnackbar } from 'stores/useSnackbar'
import { useDeleteSweepstakeById } from 'hooks/api/Sweepstake/useDeleteSweepstakeById'

export const DeleteSweepstakesRenderCell = ({
  params,
}: {
  params: GridRenderCellParams<Sweepstake>
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)

  const handleMenuOpen = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = (id?: number | null) => {
    useDeleteSweepstakeMutation.mutate({ id })
  }

  const useDeleteSweepstakeMutation = useDeleteSweepstakeById({
    onSuccess: () => {
      setSnackbarMessage(`Sweepstake successfully deleted`, 'success')
    },
    onError: () => {
      setSnackbarMessage(
        `An error has occured while deleting the Sweepstake`,
        'error'
      )
    },
  })

  return (
    <>
      <IconButton onClick={handleMenuOpen}>
        <img src={ListBullet} width={4} height={16} alt="options icon" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose()
            handleDelete(params.row.id)
          }}
        >
          Delete Sweepstake
        </MenuItem>
      </Menu>
    </>
  )
}
