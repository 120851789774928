import { useQueryClient } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useCallback, useMemo } from 'react'
import { useSnackbar } from 'stores/useSnackbar'
import { textValidation } from 'utils/textValidation'
import * as yup from 'yup'

export const useJJConnectUserSchema = () => {
  const setMessage = useSnackbar((state) => state.setMessage)
  const getEmailExists = useTypedApiClient({
    path: '/admin/licensed-establishments/users/emailmatch',
    method: 'get',
  })
  const queryClient = useQueryClient()

  const checkEmailAuthorized = useCallback(
    async (email: string) => {
      if (!email) {
        return true
      }
      try {
        const emailExistsQuery = await queryClient.fetchQuery({
          queryKey: [
            '/le-connect/licensed-establishments/users/emailmatch',
            email,
          ],
          queryFn: async () => {
            return await getEmailExists({
              email,
            })
          },
          staleTime: 60 * 1000,
        })
        return emailExistsQuery
      } catch (error: any) {
        if (error.status !== 401) {
          setMessage(error.data, 'error')
        }
        return error
      }
    },
    [getEmailExists, queryClient]
  )

  const schema = useMemo(() => {
    return yup.object().shape({
      email: yup
        .string()
        .required('Email is required.')
        .email('Please enter a valid email.')
        .test(
          'uniqueEmail',
          'Account not authorized, contact J&J Administrators for more information.',
          async (value) => {
            const { data } = await checkEmailAuthorized(value)
            if (data?.error) {
              return false
            }
            return true
          }
        )
        .test(
          'accountExists',
          'An account with this email already exists.',
          async (value) => {
            const { data } = await checkEmailAuthorized(value)
            if (data?.error || data === undefined) {
              return true
            }
            return data
          }
        ),
      firstName: textValidation('First name').required(),
      lastName: textValidation('Last name').required(),
      corporateAccountId: yup.number().optional(),
      id: yup.number().optional(),
      smartId: yup.number().nullable().optional(),
    })
  }, [checkEmailAuthorized, yup])
  return { schema }
}
