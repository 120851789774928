import { TextField } from 'components/Shared/TextField'
import {
  Controller,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form'
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { Link } from 'react-router-dom'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { type InferType } from 'yup'
import { DateTimeField } from '@mui/x-date-pickers'
import { type CampaignSchema } from './CampaignSchema'
import { LocationSelect } from './LocationSelect'
import { TypeOfMessageSelect } from './TypeOfMessageSelect'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { SweepstakeSelect } from './SweepstakeSelect'
import { ExcludedOptionsSelect } from './ExcludedOptionSelect'
import { SelectPatronTier } from 'components/SelectPatronTier'
import { SelectCorporateAccount } from 'components/SelectCorporateAccount'
import { ActivityButton } from 'components/ActivityButton'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export const CampaignMessageForm = ({
  updateIsActive,
}: {
  updateIsActive: boolean
}) => {
  const { register, control, watch } =
    useFormContext<InferType<typeof CampaignSchema>>()
  const { errors, isValid } = useFormState<InferType<typeof CampaignSchema>>()
  const typeOfMessage = watch('typeOfMessage')

  // use useWatch to watch the linkLabel and linkUrl fields without slowing down the text input fields
  const [linkLabel, linkUrl] = useWatch({
    control,
    name: ['linkLabel', 'linkUrl'],
  })

  return (
    <>
      <TypeOfMessageSelect />
      {typeOfMessage === 'Location' && (
        <Alert severity="success" icon={<InfoOutlinedIcon />}>
          This message would be sent to all patrons who have checked in to this
          location at least once.
        </Alert>
      )}
      {typeOfMessage === 'Location' && (
        <LocationSelect
          {...register('licensedEstablishments', { required: true })}
        />
      )}

      {typeOfMessage === 'Corporate' && (
        <Alert severity="success" icon={<InfoOutlinedIcon />}>
          This message would be sent to all patrons who are tied to a corporate
          account through check-ins.
        </Alert>
      )}
      {typeOfMessage === 'Corporate' && (
        <SelectCorporateAccount
          required
          {...register('corporateAccountId', { required: true })}
          label="Corporate Accounts"
          fullWidth
          corporateAccountIdKey="corporateAccountId"
          placeholder="Select a corporate account"
        />
      )}
      {typeOfMessage === 'Sweepstakes' && (
        <Alert severity="success" icon={<InfoOutlinedIcon />}>
          This message would be sent to all patrons who have earned an entry
          into this sweepstake.
        </Alert>
      )}
      {typeOfMessage === 'Sweepstakes' && <SweepstakeSelect />}
      {typeOfMessage === 'AllPatrons' && (
        <Alert severity="success" icon={<InfoOutlinedIcon />}>
          This message would be sent to all patrons. Optionally, you can
          exclude:
          <br />- patrons that HAVE checked in at any Establishment
          <br />- patrons that have ONLY visited one Establishment
          <br />- patrons who have ONLY visited Establishments associated with
          an Organization or Corporate Account
        </Alert>
      )}
      {typeOfMessage === 'AllPatrons' && <ExcludedOptionsSelect />}

      {typeOfMessage === 'AllPatronsByTier' && (
        <Alert severity="success" icon={<InfoOutlinedIcon />}>
          This message would be sent to all patrons who have the selected tier.
        </Alert>
      )}
      {typeOfMessage === 'AllPatronsByTier' && (
        <SelectPatronTier {...register('tierId')} label="Tier" fullWidth />
      )}

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography component="span">
            <b>Filters</b>
          </Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails>
          <InputLabel sx={{ fontSize: '16px', fontWeight: 'bold' }}>
            Gender
          </InputLabel>
          <Stack rowGap={2}>
            <div className="md:w-1/2 flex flex-col">
              <Select
                fullWidth
                placeholder="Gender"
                {...register('filterGender')}
                defaultValue={'Both'}
              >
                <MenuItem value="Both">Both</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="PreferNotToAnswer">
                  Prefer Not To Answer
                </MenuItem>
              </Select>
            </div>
            <TextField
              label={'Age Range'}
              placeholder="Enter Age Range i.e. 21-35"
              {...register('filterAgeRange')}
              helperText="5 charcters max"
            />
            <TextField
              label={'State'}
              placeholder="Enter State(s) of check-in i.e. IL or IL,IN"
              multiline={true}
              {...register('filterState')}
              helperText="200 charcters max"
            />
            <TextField
              label={'Zip Code'}
              placeholder="Enter Zip Code(s) of check-in i.e. 61920 or 61920,62565"
              multiline={true}
              {...register('filterZipCode')}
              helperText="500 charcters max"
            />
            <FormControlLabel
              control={
                <Checkbox {...register('filterIncompleteProfiles', {})} />
              }
              label="Incomplete Profiles (Patron's that do not have an address record)"
            />
          </Stack>
        </AccordionDetails>
      </Accordion>

      <TextField
        label={'Subject Line'}
        required
        placeholder="Enter Subject Line"
        {...register('subjectLine')}
        helperText="50 charcters max"
        bottomContent={
          errors.subjectLine?.message && (
            <FormFieldErrorMessage message={errors.subjectLine.message} />
          )
        }
      />

      <TextField
        label="Message Content"
        required
        rows={4}
        multiline
        placeholder="Enter description of Message"
        {...register('messageContent')}
        bottomContent={
          errors.messageContent?.message && (
            <FormFieldErrorMessage message={errors.messageContent.message} />
          )
        }
      />

      <TextField
        label={'Link Label'}
        required={!!linkUrl} // display as required if linkUrl is present, remove requried if linkUrl is empty
        error={
          linkUrl
            ? !!(errors.linkLabel?.message && linkUrl.length > 0) // display error if linkUrl is present, remove error if linkUrl is empty
            : false
        }
        placeholder="Enter a label for the link"
        {...register('linkLabel', {
          setValueAs: (value) => value || null, // save value as NULL instead of an empty string if field is empty
        })}
        helperText="30 characters max"
        bottomContent={
          errors.linkLabel?.message &&
          linkUrl && (
            <FormFieldErrorMessage message={errors.linkLabel.message} /> // display error message if linkUrl is present and there is an error
          )
        }
      />

      <TextField
        label={'Link URL'}
        required={!!linkLabel} // display as required if linkLabel is present, remove requried if linkLabel is empty
        error={
          linkLabel
            ? !!(errors.linkUrl?.message && linkLabel.length > 0) // display error if linkLabel is present, remove error if linkLabel is empty
            : false
        }
        placeholder="https://www.example.com"
        {...register('linkUrl', {
          setValueAs: (value) => value || null, // save value as NULL instead of an empty string if field is empty
        })}
        helperText="200 characters max"
        bottomContent={
          errors.linkUrl?.message &&
          linkLabel && (
            <FormFieldErrorMessage message={errors.linkUrl.message} /> // display error if linkLabel is present, remove error if linkLabel is empty
          )
        }
      />

      <Box>
        <FormControl>
          <InputLabel required htmlFor="scheduledDate">
            Scheduled Date
          </InputLabel>
          <Controller
            name="scheduledDate"
            control={control}
            render={({ field }) => (
              <DateTimeField id="scheduledDate" fullWidth {...field} />
            )}
          />
        </FormControl>
      </Box>

      <Stack direction="row" gap={2} data-testid="buttons">
        <Button
          variant="outlined"
          component={Link}
          to="/Communications"
          aria-label="Cancel"
        >
          Cancel
        </Button>
        <ActivityButton
          active={updateIsActive}
          disabled={!isValid}
          variant="contained"
          type="submit"
          aria-label="Schedule Message"
        >
          Schedule Message
        </ActivityButton>
      </Stack>
    </>
  )
}
