import { type UseQueryResult, useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type components } from 'src/api/playerPayback/api'

export const useGetCheckInPolicyByRewardsProgramId = ({
  rewardsProgramId,
}: {
  rewardsProgramId: number
}): UseQueryResult<components['schemas']['FullCheckInPolicyDTO']> => {
  const get = useTypedApiClient({
    path: '/admin/policies/checkins/rewards-programs/{id}',
    method: 'get',
  })
  return useQuery({
    queryKey: ['/admin/policies/checkins/rewards-programs'],
    queryFn: async () => {
      const { data } = await get({ id: rewardsProgramId })
      return data
    },
  })
}
