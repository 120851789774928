import { MenuItem, Select } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

const GrandPrizeTypes = ['GrandPrizeOnly', 'GrandPrizeWithLocation']

export const GrandPrizeTypeSelect = () => {
  const {
    control,
    formState: { defaultValues },
  } = useFormContext()

  return (
    <Controller
      render={({ field }) => (
        <Select
          {...field}
          labelId="Sweepstake.grandPrizeType"
          variant="outlined"
          fullWidth
          id="Sweepstake.grandPrizeType"
        >
          {GrandPrizeTypes.map((type) => (
            <MenuItem value={type} key={type}>
              {type.replace(/([A-Z])/g, ' $1').replace(/^ +/, '')}
            </MenuItem>
          ))}
        </Select>
      )}
      name="Sweepstake.grandPrizeType"
      control={control}
      defaultValue={defaultValues?.Sweepstake.grandPrizeType}
    />
  )
}
