import { Box, Stack } from '@mui/material'
import { Page } from 'components/Shared/Page'
import { PageHeader } from 'components/Shared/PageHeader'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { usePostCampaign } from 'hooks/api/usePostCampaign'
import { CampaignMessageForm } from './CampaignMessageForm'
import { CampaignSchema } from './CampaignSchema'

export const CommunicationsMessageNewPage = () => {
  const formMethods = useForm({
    resolver: yupResolver(CampaignSchema),
    mode: 'onChange',
  })
  const { handleSubmit } = formMethods
  const { isPending, mutate: postCampaign } = usePostCampaign()

  return (
    <Page
      header={
        <PageHeader
          title="Create New Message"
          subtitle="Fill out every field in the form to create a new message."
          backPath="/Communications"
          backText="Communications"
          isSecondary={true}
        />
      }
    >
      <Box maxWidth={565}>
        <form
          onSubmit={handleSubmit((data) => {
            postCampaign(data)
          })}
        >
          <FormProvider {...formMethods}>
            <Stack gap={4}>
              <CampaignMessageForm updateIsActive={isPending} />
            </Stack>
          </FormProvider>
        </form>
      </Box>
    </Page>
  )
}
