import { useState } from 'react'
import { Button, Typography, Box } from '@mui/material'
import { colorPrimitives } from 'components/Theme'
import { type FullCheckInPolicy } from 'types/api'
import { WelcomeBackMilestoneBonusDetails } from './WelcomeBackMilestoneBonusDetails'
import { WelcomBackMilestoneBonusSettingsModal } from './WelcomBackMilestoneBonusSettingsModal'

interface WelcomeBackMilestoneBonusSectionProps {
  checkInPolicy: FullCheckInPolicy
}

export const WelcomeBackMilestoneBonusSection = ({
  checkInPolicy,
}: WelcomeBackMilestoneBonusSectionProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const toggleModalVisibility = () => {
    setIsModalVisible(!isModalVisible)
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: { xs: 'flex-start', sm: 'center' },
          pr: { xs: '0px', sm: '16px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: '16px',
            alignItems: { xs: 'flex-start', sm: 'center' },
          }}
        >
          <Typography
            variant="h3"
            fontWeight="bold"
            sx={{ whiteSpace: 'nowrap' }}
          >
            Welcome Back Milestone Bonus Points
          </Typography>
        </Box>
        <Button
          variant="text"
          onClick={toggleModalVisibility}
          sx={{
            maxWidth: { xs: '32px', sm: 'auto' },
            minWidth: 'auto',
            maxHeight: { xs: '20px', sm: 'auto' },
          }}
        >
          <Typography
            color={colorPrimitives.redGaming}
            variant="body-1"
            fontWeight="bold"
          >
            Edit
          </Typography>
        </Button>
      </Box>
      <WelcomeBackMilestoneBonusDetails checkInPolicy={checkInPolicy} />
      <WelcomBackMilestoneBonusSettingsModal
        checkInPolicy={checkInPolicy}
        isModalVisible={isModalVisible}
        toggleModalVisibility={toggleModalVisibility}
      />
    </Box>
  )
}
