import { useQuery } from '@tanstack/react-query'
import { useTypedApiClient } from 'hooks/useTypedApiClient'

export const useGetPatronMessageCountsByCampaignId = ({
  id,
}: {
  id: number
}) => {
  const get = useTypedApiClient({
    path: '/admin/patrons/messages/message-counts/{campaignId}',
    method: 'get',
  })
  return useQuery({
    queryKey: ['/admin/patrons/messages/message-counts', id],
    queryFn: async () => {
      const { data } = await get({ campaignId: id })
      return data
    },
  })
}
