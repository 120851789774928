import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { type CheckInPolicy, type FullCheckInPolicy } from 'types/api'
import {
  type UseMutationOptions,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { type ApiError } from 'openapi-typescript-fetch'

type UsePutCheckInPolicyProps = UseMutationOptions<
  FullCheckInPolicy,
  ApiError,
  CheckInPolicy & { id: number }
>

export const usePutCheckInPolicy = ({
  onSuccess,
  onError,
}: UsePutCheckInPolicyProps) => {
  const put = useTypedApiClient({
    path: '/admin/policies/checkins/{id}',
    method: 'put',
  })
  const queryClient = useQueryClient()

  return useMutation<
    FullCheckInPolicy,
    ApiError,
    CheckInPolicy & { id: number }
  >({
    mutationFn: async (data) => {
      const response = await put(data)
      return response.data
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({
        queryKey: ['/admin/policies/checkins/{id}'],
      })
      await queryClient.invalidateQueries({
        queryKey: ['/admin/policies/checkins/rewards-programs'],
      })
      await onSuccess?.(data, variables, context)
    },
    onError,
  })
}
