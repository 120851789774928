import { Box, Button, Typography } from '@mui/material'
import { colorPrimitives } from 'components/Theme'
import ExportIcon from 'assets/export.svg'
import { useGetRewardsRedemptionsByRewardsProgramIdAndTransactionDate } from 'hooks/api/RewardsRedemption/useGetRewardsRedemptionsByRewardsProgramIdAndTransactionDate'
import { RecordsInformation } from './RecordsInformation'
import type { RewardsProgram } from 'types/api'
import { useEffect, useState } from 'react'
import { RecordsExportModal } from './RecordsExportModal'
import { useSnackbar } from 'stores/useSnackbar'
import { useGetRewardsRedemptionsByTransactionDate } from 'hooks/api/RewardsRedemption/useGetRewardsRedemptionsByTransactionDate'

export const RecordsSection = ({
  rewardProgram,
  startDate,
  endDate,
  includeLERewardPrograms,
}: {
  rewardProgram: RewardsProgram
  startDate?: string
  endDate?: string
  includeLERewardPrograms?: boolean
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const setMessage = useSnackbar((state) => state.setMessage)
  const [lastRecordsLength, setLastRecordsLength] = useState<number>()

  const recordsQuery =
    includeLERewardPrograms === true
      ? useGetRewardsRedemptionsByTransactionDate({ startDate, endDate })
      : useGetRewardsRedemptionsByRewardsProgramIdAndTransactionDate({
          rewardsProgramId: Number(rewardProgram.id),
          startDate,
          endDate,
        })

  useEffect(() => {
    setLastRecordsLength(
      Array.isArray(recordsQuery.data) && recordsQuery.data
        ? recordsQuery.data.length
        : 0
    )
  }, [recordsQuery.data])

  if (recordsQuery.isError) {
    return <Typography>An error has occurred.</Typography>
  }

  const isExportingButtonDisabled = () =>
    !startDate ||
    !endDate ||
    (Array.isArray(recordsQuery.data) && recordsQuery.data.length === 0)

  const toggleModal = () => setIsModalOpen(!isModalOpen)

  return (
    <>
      <Typography variant="h6">Records</Typography>
      <Box
        className={`p-6 border-[1px] border-[${colorPrimitives.borderGray}] rounded-lg flex flex-col lg:flex-row mt-2`}
      >
        <RecordsInformation
          startDate={startDate}
          endDate={endDate}
          recordsLength={
            Array.isArray(recordsQuery.data) && recordsQuery.data?.length
              ? lastRecordsLength
              : 0
          }
        />
        <Box className="lg:w-1/4 flex lg:justify-end mt-6 lg:mt-0">
          <Button
            variant="contained"
            disabled={isExportingButtonDisabled()}
            onClick={toggleModal}
          >
            <img src={ExportIcon} width={15} height={15} alt="Export icon" />
            <Typography variant="label-CTA">Export</Typography>
          </Button>
        </Box>
      </Box>
      <RecordsExportModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        startDate={startDate}
        endDate={endDate}
        records={recordsQuery.data}
        rewardProgram={rewardProgram}
        setMessage={setMessage}
      />
    </>
  )
}
