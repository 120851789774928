import { Page } from 'components/Shared/Page'
import { RewardProgramDetailHeader } from '../RewardsProgramDetailsHeader/RewardProgramDetailHeader'
import { useParams } from 'react-router-dom'
import { useGetRewardProgramById } from 'hooks/api/useGetRewardProgramById'
import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
  styled,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers'
import { useState } from 'react'
import { format } from 'date-fns'
import { RecordsSection } from './RecordsSection'
import { isNil } from 'lodash'
import { Colors } from 'components/Theme'

const StyledDatePicker = styled(DatePicker)({
  '& .MuiFormLabel-root': {
    fontSize: '1rem',
    fontWeight: '100',
    color: Colors.muiLabels.placeholder,
  },
  '& .Mui-focused': {
    color: !Colors.muiLabels.placeholder,
  },
})

export const RewardProgramRedemptionsPage = () => {
  const { id: rewardProgramId } = useParams()
  const rewardProgramQuery = useGetRewardProgramById(Number(rewardProgramId))

  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()

  const [checked, setChecked] = useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
  }

  if (rewardProgramQuery.isPending) {
    return <ActivityIndicator size="fullPage" />
  }

  if (rewardProgramQuery.isError || isNil(rewardProgramId)) {
    return <p>An error occurred.</p>
  }

  return (
    <Page
      header={
        <RewardProgramDetailHeader
          rewardProgram={rewardProgramQuery.data}
          currentTab={`/Rewards/RewardPrograms/${Number(
            rewardProgramId
          )}/Redemption`}
        />
      }
    >
      <Typography variant="h2">Reward Redemption</Typography>
      <Box className="py-6 sm:py-8 flex flex-row gap-4 xs:justify-between">
        <StyledDatePicker
          disableFuture
          onChange={(date: any) => {
            setStartDate(format(date, 'MM/dd/yyyy'))
          }}
          label="Start Date"
        />
        <StyledDatePicker
          onChange={(date: any) => {
            setEndDate(format(date, 'MM/dd/yyyy'))
          }}
          label="End Date"
        />
        <Box
          sx={{
            m: '50px',
            fontSize: '1rem',
            fontWeight: '100',
          }}
        >
          <FormControl component="fieldset">
            <FormGroup aria-label="position" row>
              <FormControlLabel
                value="end"
                control={
                  <Switch
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Include LE Reward Programs"
                labelPlacement="end"
              />
            </FormGroup>
          </FormControl>
        </Box>
      </Box>
      <RecordsSection
        rewardProgram={rewardProgramQuery.data}
        startDate={startDate}
        endDate={endDate}
        includeLERewardPrograms={checked}
      />
    </Page>
  )
}
