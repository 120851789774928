import { object, string } from 'yup'
import { useSnackbar } from 'stores/useSnackbar'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { ActivityButton } from 'components/ActivityButton'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { usePutPatron } from 'hooks/api/Patron/usePutPatron'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { isPresent } from '@jjvgaming/player-payback-library'
import type { Patron } from 'types/api'
import type { ApiError } from 'openapi-typescript-fetch'
import { format, parseISO } from 'date-fns'
import { grey } from '@mui/material/colors'

const FlagPatronAccountAsDeactivatedInformationSchema = object({
  reason: string().required('Reason is required').max(500),
})

export const FlagPatronAccountAsDeactivatedModal = ({
  isModalOpen,
  toggleIsOpen,
  toggleModalIsOpen,
  patron,
}: {
  isModalOpen: boolean
  toggleModalIsOpen: () => void
  toggleIsOpen: () => void
  patron: Patron
}) => {
  const formMethods = useForm({
    resolver: yupResolver(FlagPatronAccountAsDeactivatedInformationSchema),
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods

  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const dob = patron.dob ? parseISO(patron.dob) : ''

  const putPatronMutation = usePutPatron({
    onSuccess: () => {
      setSnackbarMessage(`Patron has been updated.`)
      toggleModalIsOpen()
    },
    onError: (error: ApiError) => {
      if (typeof error.data === 'string') {
        if (error.data.includes('Reason')) {
          control.setError('reason', { message: error.data })
        }
      } else {
        setSnackbarMessage(`Error: Cannot save Patron information`, 'error')
      }
    },
  })

  const handleSubmitWrapper = handleSubmit((formData) => {
    if (!patron.uuid) {
      setSnackbarMessage(`Error: invalid uuid`, 'error')
      return
    }

    const currentAddress = patron.addresses ? patron.addresses[0] : null

    putPatronMutation.mutate({
      deactivated: !patron.deactivated,
      deactivatedReason: !patron.deactivated ? formData.reason : '',
      uuid: patron.uuid,
      firstName: patron.firstName ? patron.firstName : '',
      lastName: patron.lastName ? patron.lastName : '',
      email: patron.email ? patron.email : '',
      dob: dob ? format(dob, 'P') : '',
      gender: patron.gender,
      phoneNumber: String(patron.phoneNumber),
      id: patron.id,
      addresses: [
        {
          id: currentAddress ? currentAddress.id : 0,
          patronId: patron.id,
          address1: patron.addresses?.length
            ? patron.addresses[0].address1
            : null,
          address2: patron.addresses?.length
            ? patron.addresses[0].address2
            : null,
          city: patron.addresses?.length ? patron.addresses[0].city : null,
          state: patron.addresses?.length ? patron.addresses[0].state : null,
          postalCode: patron.addresses?.length
            ? patron.addresses[0].postalCode
            : null,
        },
      ],
      checkInAgain: patron.checkInAgain ?? false,
    })
  })

  return (
    <Dialog
      open={isModalOpen}
      maxWidth={'xl'}
      fullWidth
      PaperProps={{ sx: { p: 0, maxWidth: '900px' } }}
      onClose={toggleIsOpen}
    >
      <form onSubmit={handleSubmitWrapper} noValidate>
        <DialogTitle bgcolor={grey[50]} sx={{ p: 0 }} className="border-b">
          <Box px={4} py={4}>
            <Typography variant="h3" fontWeight={'bold'}>
              {`${
                !patron.deactivated ? 'Deactivate ' : 'Activate '
              } Patron Account`}
            </Typography>
            <Typography variant="body-1" color={'text.secondary'}>
              {`Patron Name: ${
                (patron.firstName ?? '') + ' ' + (patron.lastName ?? '')
              }`}
            </Typography>
          </Box>
        </DialogTitle>

        <DialogContent sx={{ pt: 4, px: 4 }}>
          <Typography variant="body-1" className="text-secondary" pb={'16px'}>
            <b>
              Are you sure you want to{' '}
              {!patron.deactivated ? ' deactivate' : ' activate'} this
              patron&apos;s account?
            </b>
          </Typography>
          <Typography
            variant="body-1"
            className="text-secondary"
            pb={'16px'}
            hidden={patron.deactivated}
          >
            Deactivating this account will essentially freeze it. Their profile
            will be marked as deactivated, and they will no longer be able to
            log in to the app or receive points, check-ins, or entries.
          </Typography>

          <div hidden={patron.deactivated}>
            <Stack direction="column" gap={1}>
              <Stack direction="column">
                <InputLabel
                  sx={{ fontSize: '16px', fontWeight: '100' }}
                  required
                >
                  Reason for Deactivation
                </InputLabel>
              </Stack>
              <Stack direction="column">
                <TextField
                  required
                  fullWidth
                  multiline
                  rows={3}
                  placeholder="Enter Reason"
                  {...register('reason')}
                  defaultValue={patron.deactivatedReason}
                  inputProps={{ maxLength: 500 }}
                  error={isPresent(errors.reason)}
                />
                <Typography variant="body-3">500 characters max</Typography>
                {errors.reason?.message && (
                  <FormFieldErrorMessage message={errors.reason.message} />
                )}
              </Stack>
            </Stack>
          </div>
        </DialogContent>
        <DialogActions
          sx={{ borderTop: `1px solid ${grey[300]}`, pt: 2, pb: 2, px: 4 }}
        >
          <Button onClick={toggleModalIsOpen}>Cancel</Button>
          <ActivityButton
            variant="contained"
            active={putPatronMutation.isPending}
            onClick={handleSubmitWrapper}
          >
            {!patron.deactivated
              ? 'Deactivate Patron Account'
              : 'Activate Patron Account'}
          </ActivityButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
