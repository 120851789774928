import { yupResolver } from '@hookform/resolvers/yup'
import { isPresent } from '@jjvgaming/player-payback-library'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { ActivityButton } from 'components/ActivityButton'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { usePutCheckInPolicy } from 'hooks/api/usePutCheckInPolicy'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'stores/useSnackbar'
import {
  type CheckInPolicy,
  type CheckInPolicyWelcomeBackMilestonePointBonusScheduleDTO,
} from 'types/api'
import { number, object } from 'yup'

interface EditWelcomeBackMilestoneBonusModalProps {
  isModalOpen: boolean
  data?: CheckInPolicyWelcomeBackMilestonePointBonusScheduleDTO | null
  checkInPolicy?: CheckInPolicy | null
  enablePatronWelcomeBackMilestoneBonus: boolean
  toggleIsOpen: () => void
}

const EditWelcomeBackMilestoneBonusModalSchema = object({
  patronCheckInLatestNumWeeks: number().required(
    'Number of weeks is a required field'
  ),
  pointBonus: number().required('Number of Points is a required field'),
})

export const EditWelcomeBackMilestoneBonusModal = ({
  isModalOpen,
  toggleIsOpen,
  data,
  checkInPolicy,
  enablePatronWelcomeBackMilestoneBonus,
}: EditWelcomeBackMilestoneBonusModalProps) => {
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const params = useParams()
  const rewardsProgramId = Number(params.id)

  const formMethods = useForm({
    resolver: yupResolver(EditWelcomeBackMilestoneBonusModalSchema),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods

  const useUpdateCheckInPolicyMutation = usePutCheckInPolicy({
    onSuccess: () => {
      setSnackbarMessage(
        `Welcome Back Milestone Bonus Schedule ID#${data?.id} successfully updated`,
        'success'
      )
      toggleIsOpen()
    },
    onError: () => {
      setSnackbarMessage(
        `An error has occured while updating the Welcome Back Milestone Bonus Schedule ID#${data?.id}`,
        'error'
      )
      toggleIsOpen()
    },
  })

  const handleSubmitWrapper = handleSubmit((formData) => {
    const updatedSchedules =
      checkInPolicy?.checkInPolicyWelcomeBackMilestonePointBonusSchedules?.map(
        (schedule) => {
          if (schedule.id === data?.id) {
            return {
              ...schedule,
              enablePatronWelcomeBackMilestoneBonus,
              pointBonus: formData.pointBonus,
              patronCheckInLatestNumWeeks: formData.patronCheckInLatestNumWeeks,
            }
          }
          return schedule
        }
      ) ?? []

    useUpdateCheckInPolicyMutation.mutate({
      ...checkInPolicy,
      enablePatronWelcomeBackMilestoneBonus,
      id: data?.checkInPolicyId ?? 0,
      rewardsProgramId,
      checkInPolicyWelcomeBackMilestonePointBonusSchedules: updatedSchedules,
    })
  })

  return (
    <Dialog
      open={isModalOpen}
      maxWidth={'xl'}
      fullWidth
      PaperProps={{ sx: { p: 0, maxWidth: '900px' } }}
      onClose={toggleIsOpen}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitWrapper} noValidate>
          <DialogTitle bgcolor={grey[50]} sx={{ p: 0 }} className="border-b">
            <Box px={4} py={4}>
              <Typography variant="h3" fontWeight={'bold'}>
                Editing Welcome Back Milestone Bonus Schedule
              </Typography>
              <Typography variant="body-1" color={'text.secondary'}>
                ID#{data?.id}
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ m: 4 }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={6}
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <InputLabel
                  sx={{ fontSize: 16, paddingTop: 2, fontWeight: 'normal' }}
                >
                  Number of Weeks
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  {...register('patronCheckInLatestNumWeeks', {
                    required: true,
                  })}
                  defaultValue={data?.patronCheckInLatestNumWeeks}
                  error={isPresent(errors.patronCheckInLatestNumWeeks)}
                />
                {errors.patronCheckInLatestNumWeeks?.message && (
                  <FormFieldErrorMessage
                    message={errors.patronCheckInLatestNumWeeks?.message}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                container
                direction="column"
                justifyContent="center"
                alignItems="flex-start"
              >
                <InputLabel
                  sx={{ fontSize: 16, paddingTop: 2, fontWeight: 'normal' }}
                >
                  Point Bonus
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  {...register('pointBonus', {
                    required: true,
                  })}
                  defaultValue={data?.pointBonus}
                  error={isPresent(errors.pointBonus)}
                />
                {errors.pointBonus?.message && (
                  <FormFieldErrorMessage message={errors.pointBonus?.message} />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{ borderTop: `1px solid ${grey[300]}`, pt: 2, pb: 2, px: 4 }}
          >
            <Button variant="text" onClick={toggleIsOpen}>
              Cancel
            </Button>
            <ActivityButton
              type="submit"
              variant="contained"
              active={useUpdateCheckInPolicyMutation.isPending}
            >
              Update Schedule
            </ActivityButton>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  )
}
