import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, InputLabel, Stack } from '@mui/material'
import { useMutation } from '@tanstack/react-query'
import { Page } from 'components/Shared/Page'
import { formatISO } from 'date-fns'
import { useApiClient } from 'hooks/useApiClient'
import { FormProvider, useForm, type Control } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'stores/useSnackbar'
import { date, object, string } from 'yup'
import { PageHeader } from 'components/Shared/PageHeader'
import { TextField } from 'components/Shared/TextField'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { isPresent } from '@jjvgaming/player-payback-library'
import { AdornedDateTimeField } from 'components/AdornedDateTimeField/AdornedDateTimeField'
import { GrandPrizeTypeSelect } from './GrandPrizeTypeSelect'

const today = new Date()
today.setHours(0, 0, 0, 0)

const sweepstakeSchema = object().shape({
  Sweepstake: object({
    name: string().required('Must enter a sweepstake name'),
    grandPrizeType: string()
      .oneOf(['GrandPrizeOnly', 'GrandPrizeWithLocation'])
      .required('Must select a sweepstake grand prize type'),
    startDate: date()
      .typeError('Must enter a valid start date')
      .required('Must enter a start date')
      .min(today, 'Start date should not be a date in the past'),
    endDate: date()
      .typeError('Must enter a valid end date')
      .required('Must enter an end date')
      .when(['startDate'], ([startDate], schema) => {
        if (startDate instanceof Date && isFinite(+startDate)) {
          return schema.min(
            startDate,
            'End date should not be before start date'
          )
        }
        return schema
      }),
    description: string()
      .required('Must enter a sweepstake description')
      .max(500),
  }),
})

type FormSchemaType<T> = T extends Control<infer R> ? R : never

export const SweepstakesNewPage = () => {
  const { post } = useApiClient()
  const setMessage = useSnackbar((state) => state.setMessage)
  const navigate = useNavigate()

  const formMethods = useForm({
    resolver: yupResolver(sweepstakeSchema),
    mode: 'all',
    defaultValues: {
      Sweepstake: {
        grandPrizeType: 'GrandPrizeOnly',
      },
    },
  })

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = formMethods

  const createSweepstake = useMutation({
    mutationKey: ['sweepstakes/create'],
    mutationFn: async (data: FormSchemaType<typeof control>) => {
      const response = await post('/admin/contests', {
        params: { header: undefined },
        body: {
          name: data.Sweepstake.name,
          grandPrizeType: data.Sweepstake.grandPrizeType,
          description: data.Sweepstake.description,
          startDate: formatISO(data.Sweepstake.startDate),
          endDate: formatISO(data.Sweepstake.endDate),
          type: 'Sweepstake',
        },
      })
      return response
    },
    onSuccess: async (response) => {
      setMessage('Sweepstake successfully created', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      if (response.data?.id) {
        navigate(`/Contests/Sweepstakes/${response.data.id}/Overview`)
      } else {
        navigate(`/Contests/Sweepstakes`)
      }
    },
    onError: async () => {
      setMessage('Error creating sweepstake', 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  const createSweepstakeWrapper = handleSubmit((data) => {
    createSweepstake.mutate(data)
  })

  return (
    <Page
      header={
        <PageHeader
          title="Create a New Sweepstake"
          backPath="/Contests/Sweepstakes"
          backText="Sweepstakes"
          isSecondary={true}
          subtitle={`You must fill out every field in the form before clicking the submit button.\nIncomplete submissions cannot be processed.`}
        />
      }
    >
      <FormProvider {...formMethods}>
        <form onSubmit={createSweepstakeWrapper} noValidate>
          <Stack spacing={4}>
            <Box>
              <TextField
                required
                error={isPresent(errors.Sweepstake?.name)}
                label="Sweepstake Name"
                placeholder="Enter sweepstake name"
                {...register('Sweepstake.name')}
              />
              {errors.Sweepstake?.name?.message && (
                <FormFieldErrorMessage
                  message={errors.Sweepstake?.name.message}
                />
              )}
            </Box>

            <Box
              sx={{
                maxWidth: '520px',
              }}
            >
              <Box>
                <InputLabel required id="Sweepstake.grandPrizeType">
                  Grand Prize Type
                </InputLabel>
              </Box>
              <Box>
                <GrandPrizeTypeSelect />

                {errors.Sweepstake?.grandPrizeType?.message && (
                  <FormFieldErrorMessage
                    message={errors.Sweepstake?.grandPrizeType.message}
                  />
                )}
              </Box>
            </Box>

            <Box>
              <AdornedDateTimeField
                name="Sweepstake.startDate"
                control={control}
                label="Start Date/Time"
              />
              {errors.Sweepstake?.startDate?.message && (
                <FormFieldErrorMessage
                  message={errors.Sweepstake?.startDate.message}
                />
              )}
            </Box>
            <Box>
              <AdornedDateTimeField
                name="Sweepstake.endDate"
                control={control}
                label="End Date/Time"
              />
              {errors.Sweepstake?.endDate?.message && (
                <FormFieldErrorMessage
                  message={errors.Sweepstake?.endDate.message}
                />
              )}
            </Box>
            <Box>
              <TextField
                multiline
                rows={3}
                required
                error={isPresent(errors.Sweepstake?.description)}
                label="Sweepstake Overview"
                placeholder="Description of sweepstake"
                {...register('Sweepstake.description')}
              />
              {errors.Sweepstake?.description?.message && (
                <FormFieldErrorMessage
                  message={errors.Sweepstake?.description.message}
                />
              )}
            </Box>
            <Stack direction="row" spacing={2}>
              <Button
                type="submit"
                variant="contained"
                disabled={createSweepstake.isPending}
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  navigate('/Contests/Sweepstakes')
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </Page>
  )
}
