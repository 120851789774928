import { isPresent } from '@jjvgaming/player-payback-library'
import { object } from 'yup'
import { formatISO, parse, parseISO } from 'date-fns'
import {
  Alert,
  Button,
  Checkbox,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import EventIcon from '@mui/icons-material/Event'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage'
import { HookableDateField } from 'components/Shared/HookableDateField'
import { HookableTimeField } from 'components/Shared/HookableTimeField'
import { useState } from 'react'
import { ActivityButton } from 'components/ActivityButton'
import { Modal } from 'components/Modal/Modal'
import { useLayout } from 'hooks/useLayout'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import {
  rewardItemNameSchema,
  rewardItemDescriptionSchema,
  rewardItemTypeSchema,
  rewardItemSKUSchema,
  rewardItemVendorItemNameSchema,
  rewardItemGiftCardSchema,
  rewardItemScheduledRewardItemSchema,
  rewardItemStartDateSchema,
  rewardItemStartTimeSchema,
  rewardItemEndDateSchema,
  rewardItemEndTimeSchema,
} from 'utils/schemas/RewardItemSchema'
import { type EditRewardItemDetailsProps } from './types'
import { usePutReward } from 'hooks/api/usePutReward'
import { useSnackbar } from 'stores/useSnackbar'
import { type Reward } from 'types/api'

export const RewardItemSchema = object().shape({
  name: rewardItemNameSchema,
  description: rewardItemDescriptionSchema,
  type: rewardItemTypeSchema,
  sku: rewardItemSKUSchema,
  vendorItemName: rewardItemVendorItemNameSchema,
  giftCard: rewardItemGiftCardSchema,
  scheduledRewardItem: rewardItemScheduledRewardItemSchema,
  startDate: rewardItemStartDateSchema,
  startTime: rewardItemStartTimeSchema,
  endDate: rewardItemEndDateSchema,
  endTime: rewardItemEndTimeSchema,
})

export const EditRewardItem = ({
  reward,
  isModalOpen,
  toggleModalIsOpen,
}: EditRewardItemDetailsProps) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : '75%'
  const setSnackbarMessage = useSnackbar((state) => state.setMessage)
  const [giftCard, setGiftCard] = useState(reward.giftCard ?? false)

  const [selectedRewardItemType, setSelectedRewardItemType] = useState(
    reward.type || 'LocationOffer'
  )

  const [selectedScheduledRewardItem, setSelectedScheduledRewardItem] =
    useState(reward.startDate ? 'Yes' : 'No')

  const formMethods = useForm({
    resolver: yupResolver(RewardItemSchema),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = formMethods

  const putRewardItemMutation = usePutReward({
    id: reward.id,
    onSuccess: () => {
      setSnackbarMessage(`Reward item has been updated`)
      toggleModalIsOpen()
    },
    onError: () => setSnackbarMessage(`Error updating reward item`, 'error'),
  })

  const handleSubmitWrapper = handleSubmit((data) => {
    putRewardItemMutation.mutate({
      ...reward,
      name: data.name,
      description: data.description,
      type: selectedRewardItemType,
      vendorItemName: data.vendorItemName,
      sku: data.sku,
      giftCard: data.giftCard,
      startDate:
        data.scheduledRewardItem === 'Yes'
          ? data.startDate && data.startTime
            ? formatISO(parse(data.startTime, 'hh:mm a', data.startDate))
            : null
          : null,
      endDate:
        data.scheduledRewardItem === 'Yes'
          ? data.endDate && data.endTime
            ? formatISO(parse(data.endTime, 'hh:mm a', data.endDate))
            : null
          : null,
    })
  })

  const handleTypeChange = (e: SelectChangeEvent) => {
    setSelectedRewardItemType(e.target.value as Reward['type'])
  }

  const defaultStartDate = reward.startDate ? parseISO(reward.startDate) : null
  const defaultEndDate = reward.endDate ? parseISO(reward.endDate) : null

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: isMobile ? '100%' : '55%',
        height: heightModal,
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitWrapper} noValidate>
          <ModalFullHeader
            title={reward.name}
            toggleIsOpen={toggleModalIsOpen}
          />
          <ModalFullBody heightModal={heightModal}>
            <Grid container>
              <Grid container>
                {Object.keys(errors).length > 0 && (
                  <Grid item xs={12} pb={4}>
                    <Alert severity="error">{Object.keys(errors)}</Alert>
                    Please correct errors to continue
                  </Grid>
                )}

                <Grid item xs={12} md={6} pt={{ md: 2 }}>
                  <InputLabel
                    required
                    htmlFor="name"
                    sx={{ fontSize: '16px', fontWeight: '100', width: '100%' }}
                  >
                    Reward Item Name
                  </InputLabel>
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    required
                    error={isPresent(errors.name)}
                    placeholder="Enter Reward Item Name"
                    defaultValue={reward?.name}
                    {...register('name')}
                  />
                  {errors.name?.message && (
                    <FormFieldErrorMessage message={errors.name.message} />
                  )}
                </Grid>
              </Grid>

              <Grid container pt={4}>
                <Grid item xs={12} md={6} pt={{ md: 2 }}>
                  <InputLabel
                    htmlFor="type"
                    required
                    sx={{ fontSize: '16px', fontWeight: '100' }}
                  >
                    Reward Item Type
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    {...register('type', { required: true })}
                    inputProps={{ id: 'rewardType' }}
                    error={isPresent(errors.type)}
                    value={selectedRewardItemType}
                    sx={{ width: '100%', maxWidth: '520px' }}
                    onChange={handleTypeChange}
                  >
                    <MenuItem value="LocationOffer">Location Offer</MenuItem>
                    <MenuItem value="JJStoreItem">J&J Store</MenuItem>
                    <MenuItem value="MatchPlay">Match Play</MenuItem>
                  </Select>
                </Grid>
              </Grid>

              {selectedRewardItemType === 'JJStoreItem' && (
                <Grid container pt={4}>
                  <Grid item xs={12} md={6} pt={{ md: 2 }}>
                    <InputLabel
                      htmlFor="type"
                      sx={{ fontSize: '16px', fontWeight: '100' }}
                    >
                      Vendor Item Name
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      required
                      error={isPresent(errors.vendorItemName)}
                      placeholder="Vendor Item Name"
                      defaultValue={reward?.vendorItemName}
                      {...register('vendorItemName')}
                    />
                    {errors.vendorItemName?.message && (
                      <FormFieldErrorMessage
                        message={errors.vendorItemName.message}
                      />
                    )}
                  </Grid>
                </Grid>
              )}

              {selectedRewardItemType === 'JJStoreItem' && (
                <Grid container pt={4}>
                  <Grid item xs={12} md={6} pt={{ md: 2 }}>
                    <InputLabel
                      htmlFor="type"
                      sx={{ fontSize: '16px', fontWeight: '100' }}
                    >
                      SKU
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      required
                      error={isPresent(errors.sku)}
                      placeholder="SKU"
                      defaultValue={reward?.sku}
                      helperText={'Alphanumeric characters only'}
                      {...register('sku')}
                    />
                    {errors.sku?.message && (
                      <FormFieldErrorMessage message={errors.sku.message} />
                    )}
                  </Grid>
                </Grid>
              )}

              {selectedRewardItemType === 'JJStoreItem' && (
                <Grid container pt={4}>
                  <Grid item xs={12} md={6} pt={{ md: 2 }}>
                    <InputLabel
                      htmlFor="eGiftCard"
                      sx={{ fontSize: '16px', fontWeight: '100' }}
                    ></InputLabel>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <div className="flex container items-center">
                      <Checkbox
                        checked={giftCard}
                        onChange={(e) => setGiftCard(e.target.checked)}
                      />
                      <div className="mt-1">
                        <Typography>eGift Card</Typography>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )}

              <Grid container pt={4}>
                <Grid item xs={12} md={6} pt={{ md: 2 }}>
                  <InputLabel
                    htmlFor="description"
                    sx={{ fontSize: '16px', fontWeight: '100' }}
                  >
                    Reward Item Description
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    multiline
                    rows={5}
                    required
                    error={isPresent(errors.description)}
                    placeholder="Describe the item"
                    {...register('description')}
                    defaultValue={reward.description}
                    sx={{ width: '100%' }}
                  />
                  <Grid item xs={12} md={6}>
                    <Typography variant="body-3">500 characters max</Typography>
                  </Grid>
                  {errors.description?.message && (
                    <FormFieldErrorMessage
                      message={errors.description.message}
                    />
                  )}
                </Grid>
              </Grid>

              <Grid container pt={4}>
                <Grid item xs={12} md={6} pt={{ md: 2 }}>
                  <InputLabel
                    htmlFor="scheduledRewardItem"
                    required
                    sx={{ fontSize: '16px', fontWeight: '100' }}
                  >
                    Do you want to schedule this Reward Item?
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    {...register('scheduledRewardItem', {
                      required: true,
                    })}
                    inputProps={{ id: 'scheduledBoolean' }}
                    data-testid="scheduledBoolean"
                    error={isPresent(errors.scheduledRewardItem)}
                    value={selectedScheduledRewardItem}
                    onChange={(e) =>
                      setSelectedScheduledRewardItem(e.target.value)
                    }
                    sx={{ width: '100%', maxWidth: '520px' }}
                  >
                    <MenuItem value="No">No</MenuItem>
                    <MenuItem value="Yes">Yes</MenuItem>
                  </Select>
                  {errors.scheduledRewardItem?.message && (
                    <FormFieldErrorMessage
                      message={errors.scheduledRewardItem.message}
                    />
                  )}
                </Grid>
              </Grid>

              {selectedScheduledRewardItem === 'Yes' && (
                <Grid container pt={4}>
                  <Grid item xs={12} md={6} pt={{ md: 2 }}>
                    <InputLabel
                      htmlFor="startDate"
                      required
                      sx={{ fontSize: '16px', fontWeight: '100' }}
                    >
                      Start Date/Time
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={2.45}>
                    <HookableDateField
                      slotProps={{
                        textField: {
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <EventIcon />
                              </InputAdornment>
                            ),
                          },
                          error: isPresent(errors.startDate),
                        },
                      }}
                      defaultValue={defaultStartDate}
                      {...register('startDate')}
                    />
                    {errors.startDate?.message && (
                      <FormFieldErrorMessage
                        message={errors.startDate.message}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={0.2}></Grid>
                  <Grid item xs={12} md={2.45}>
                    <HookableTimeField
                      defaultValue={defaultStartDate as string | null}
                      {...register('startTime')}
                      slotProps={{
                        textField: {
                          required: true,
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccessTimeIcon />
                              </InputAdornment>
                            ),
                          },
                          error: isPresent(errors.startTime),
                        },
                      }}
                    />
                    {errors.startTime?.message && (
                      <FormFieldErrorMessage
                        message={errors.startTime.message}
                      />
                    )}
                  </Grid>
                </Grid>
              )}

              {selectedScheduledRewardItem === 'Yes' && (
                <Grid container pt={4}>
                  <Grid item xs={12} md={6} pt={{ md: 2 }}>
                    <InputLabel
                      htmlFor="endDate"
                      sx={{ fontSize: '16px', fontWeight: '100' }}
                    >
                      End Date/Time
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={2.45}>
                    <HookableDateField
                      defaultValue={defaultEndDate}
                      sx={{ width: '100%' }}
                      slotProps={{
                        textField: {
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <EventIcon />
                              </InputAdornment>
                            ),
                          },
                          error: isPresent(errors.endDate),
                        },
                      }}
                      {...register('endDate')}
                    />
                    {errors.endDate?.message && (
                      <FormFieldErrorMessage message={errors.endDate.message} />
                    )}
                  </Grid>
                  <Grid item xs={12} md={0.2}></Grid>
                  <Grid item xs={12} md={2.45}>
                    <HookableTimeField
                      {...register('endTime')}
                      defaultValue={defaultEndDate as string | null}
                      slotProps={{
                        textField: {
                          required: true,
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                <AccessTimeIcon />
                              </InputAdornment>
                            ),
                          },
                          error: isPresent(errors.endTime),
                        },
                      }}
                      sx={{ width: '100%' }}
                    />
                    {errors.endTime?.message && (
                      <FormFieldErrorMessage message={errors.endTime.message} />
                    )}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </ModalFullBody>
          <ModalFullFooter>
            <Button variant="text" onClick={toggleModalIsOpen}>
              Cancel
            </Button>
            <ActivityButton
              active={putRewardItemMutation.isPending}
              type="submit"
              variant="contained"
            >
              Save
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </FormProvider>
    </Modal>
  )
}
