import { useTypedApiClient } from 'hooks/useTypedApiClient'
import { useMutation, useQueryClient } from '@tanstack/react-query'

interface useDeleteSweepstakeParams {
  id: number | null | undefined
}

interface useDeleteSweepstakeProps {
  onSuccess?: (data: undefined, variables: useDeleteSweepstakeParams) => void
  onError?: (error: Error, variables: useDeleteSweepstakeParams) => void
}

export const useDeleteSweepstakeById = ({
  onSuccess,
  onError,
}: useDeleteSweepstakeProps) => {
  const deleteSweepstake = useTypedApiClient({
    path: '/admin/contests/{id}',
    method: 'delete',
  })
  const queryClient = useQueryClient()

  return useMutation<any, Error, useDeleteSweepstakeParams>({
    mutationFn: async ({ id }: useDeleteSweepstakeParams) => {
      await deleteSweepstake({ id: Number(id) })
    },
    onSuccess: async (data, variables) => {
      await queryClient.invalidateQueries({
        queryKey: ['/sweepstakes'],
      })
      onSuccess?.(data, variables)
    },
    onError,
  })
}
