import { Box, Button, Divider, Typography } from '@mui/material'
import { Modal } from 'components/Modal/Modal'
import { Colors, colorPrimitives } from 'components/Theme'
import type { RewardsProgram, RewardsRedemption } from 'types/api'
import { addOpacity } from 'utils/util'
import { DataTable } from 'components/Shared/DataTable'
import { GridToolbar, useGridApiRef } from '@mui/x-data-grid'
import { RewardsRedemptionsCSVColumns } from './RewardsRedemptionsCSVColumns'
import FileIcon from 'assets/file.svg'

export const RecordsExportModal = ({
  isModalOpen,
  toggleModal,
  rewardProgram,
  startDate,
  endDate,
  records,
  setMessage,
}: {
  isModalOpen: boolean
  toggleModal: () => void
  rewardProgram: RewardsProgram
  startDate?: string
  endDate?: string
  records?: {
    rewardsProgramId?: number
    startTransactionDate?: string
    endTransactionDate?: string
  }

  setMessage: (message: string | null) => void
}) => {
  const dataTableApiRef = useGridApiRef()

  const exportRewardsRedemptions = () => {
    dataTableApiRef.current.exportDataAsCsv({
      fileName: `${rewardProgram.name} - Redemptions From ${startDate} - to ${endDate}`,
      utf8WithBom: true,
    })
    toggleModal()
    setMessage('Records have been downloaded.')
  }

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={toggleModal}
      sx={{
        height: { xs: '100%', sm: '50%' },
        width: { xs: '100%', sm: '40%' },
        overflow: 'hidden',
      }}
    >
      <>
        <Box
          paddingY={3}
          paddingX={{ xs: 2, sm: 4 }}
          bgcolor={Colors.backgroundGray}
        >
          <Typography variant="h2">Export</Typography>
        </Box>
        <Divider />
        <Box paddingX={{ xs: 2, sm: 4 }} paddingY={{ xs: 3, sm: 4 }}>
          <Typography variant="h5">{rewardProgram.name}</Typography>
          <Box
            className={`p-6 border-[1px] border-[${colorPrimitives.borderGray}] rounded-lg mt-6 mb-3`}
          >
            <Box className="flex flex-col lg:flex-row gap-6 lg:w-3/4 lg:items-center">
              <Box className="min-w-[200px]">
                <Typography variant="h5">Records to export</Typography>
                <Box className="flex flex-row items-stretch gap-1">
                  <img
                    src={FileIcon}
                    width={20}
                    alt="Export icon"
                    className="mb-1"
                  />
                  <Typography variant="body-1">
                    {`${Array.isArray(records) ? records.length : 0} records`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Typography
            variant="body-2"
            color={addOpacity(colorPrimitives.black, 0.6)}
          >
            CSV file format will be exported
          </Typography>
        </Box>
        <Box
          className="fixed bottom-0 border-t-[1px] w-full flex justify-end gap-2
   px-8 py-6 sm:p-4"
        >
          <Button variant="text" onClick={toggleModal}>
            Cancel
          </Button>
          <Button
            variant="contained"
            type="button"
            onClick={exportRewardsRedemptions}
          >
            Export
          </Button>
        </Box>
        <Box display="none">
          <DataTable
            apiRef={dataTableApiRef}
            columns={RewardsRedemptionsCSVColumns()}
            rows={(records as unknown as RewardsRedemption[]) ?? []}
            slots={{ toolbar: GridToolbar }}
            forceDesktopTableInMobile
          />
        </Box>
      </>
    </Modal>
  )
}
