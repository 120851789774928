import {
  Alert,
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'
import { isNil } from 'lodash'
import { StateSelect } from './StateSelect'
import { useLayout } from 'hooks/useLayout'
import { Modal } from 'components/Modal/Modal'
import { object, string, date, number } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ActivityButton } from 'components/ActivityButton'
import { isPresent } from '@jjvgaming/player-payback-library'
import { ModalFullBody } from 'components/Modal/ModalFullBody'
import { formatISO, isAfter, isValid, parseISO } from 'date-fns'
import { ModalFullFooter } from 'components/Modal/ModalFullFooter'
import { ModalFullHeader } from 'components/Modal/ModalFullHeader'
import { FormProvider, useForm } from 'react-hook-form'
import { type Sweepstake, type SweepstakeStatusType } from 'types/api'
import { type EditSweepstakeDetailsProps } from './SweepstakeDetailsSection'
import { FormFieldErrorMessage } from 'components/FormFieldErrorMessage/FormFieldErrorMessage'
import { AdornedDateTimeField } from 'components/AdornedDateTimeField/AdornedDateTimeField'
import { GrandPrizeTypeSelect } from '../GrandPrizeTypeSelect'

export const EditSweepstakeDetails = ({
  sweepstake,
  onSubmitSweepstake,
  toggleModalIsOpen,
  updateIsActive,
  isModalOpen,
}: EditSweepstakeDetailsProps) => {
  const { isMobile } = useLayout()
  const heightModal = isMobile ? '100%' : '85%'
  const startDateModify = getStartDate(sweepstake)

  const SweepstakeDetailsSchema = object().shape({
    Sweepstake: object({
      name: string().required('Must enter a sweepstake name'),
      grandPrizeType: string()
        .oneOf(['GrandPrizeOnly', 'GrandPrizeWithLocation'])
        .required('Must select a sweepstake grand prize type'),
      state: string<SweepstakeStatusType>().required(
        'State is a required field'
      ),
      startDate: date()
        .typeError('Must enter a valid start date')
        .required('Must enter a start date')
        .test(
          'DayInPast',
          'Start date should not be a date in the past',
          (value) => {
            return !isNil(startDateModify) &&
              startDateModify.toISOString() !== value.toISOString()
              ? isAfter(value, new Date())
              : true
          }
        ),
      endDate: date()
        .typeError('Must enter a valid end date')
        .required('Must enter an end date')
        .when(['startDate'], ([startDate], schema) => {
          if (isValid(startDate)) {
            return schema.min(
              startDate,
              'End date should not be before start date'
            )
          }
          return schema
        }),
      numGrandPrizeWinnersPerTier: number()
        .required('Must enter a number of Grand Prize winners per tier')
        .transform((val) => (isNaN(val) ? undefined : val))
        .min(
          1,
          'Number of Grand Prize Winners must be greater than or equal to 0'
        )
        .max(99, 'Number of Grand Prize Winners must be less than 100'),
      description: string()
        .required('Must enter a sweepstake description')
        .max(500),
      url: string().max(500),
    }),
  })

  const formMethods = useForm({
    resolver: yupResolver(SweepstakeDetailsSchema),
    mode: 'all',
    defaultValues: {
      Sweepstake: {
        name: sweepstake.name,
        grandPrizeType: sweepstake.grandPrizeType,
        startDate: getStartDate(sweepstake),
        endDate: getEndDate(sweepstake),
        state: sweepstake.sweepstakeData?.state ?? 'Draft',
        numGrandPrizeWinnersPerTier:
          sweepstake.sweepstakeData?.numGrandPrizeWinnersPerTier,
        url: sweepstake.url ?? undefined,
        description: sweepstake.description ?? undefined,
      },
    },
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = formMethods

  const handleSubmitWrapper = handleSubmit((data) => {
    onSubmitSweepstake({
      ...sweepstake,
      name: data.Sweepstake.name,
      grandPrizeType: data.Sweepstake.grandPrizeType,
      startDate: formatISO(data.Sweepstake.startDate),
      endDate: formatISO(data.Sweepstake.endDate),
      url: data.Sweepstake.url,
      description: data.Sweepstake.description,
      sweepstakeData: {
        contestId: sweepstake.id,
        id: sweepstake.sweepstakeData?.id ?? NEW_SWEEPSTAKE_DATA_ID,
        numGrandPrizeWinnersPerTier:
          data.Sweepstake.numGrandPrizeWinnersPerTier,
        state: data.Sweepstake.state,
        participationGuidelines:
          sweepstake.sweepstakeData?.participationGuidelines,
        enrollableOpenedDate: sweepstake.sweepstakeData?.enrollableOpenedDate,
        enrollableClosedDate: sweepstake.sweepstakeData?.enrollableClosedDate,
        promoted: sweepstake.sweepstakeData?.promoted,
      },
    })
  })

  return (
    <Modal
      isOpen={isModalOpen}
      toggleIsOpen={() => {}}
      sx={{
        width: isMobile ? '100%' : '60%',
        height: heightModal,
      }}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmitWrapper} noValidate>
          <ModalFullHeader
            toggleIsOpen={toggleModalIsOpen}
            title={'Edit Sweepstake Overview'}
          />
          <ModalFullBody heightModal={heightModal}>
            <Grid container rowSpacing={2}>
              {errors.Sweepstake && (
                <Grid item xs={12} pb={4}>
                  <Alert severity="error">
                    Please correct errors to continue
                  </Alert>
                </Grid>
              )}

              <Grid item container alignItems={'center'} xs={12} md={12}>
                <Grid item xs={12} md={6}>
                  <InputLabel required id="name" sx={{ fontWeight: 'normal' }}>
                    Sweepstake Name
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    {...register('Sweepstake.name', {
                      required: true,
                    })}
                    error={isPresent(errors.Sweepstake?.name)}
                    sx={{ width: '100%' }}
                  />
                  {errors.Sweepstake?.name?.message && (
                    <FormFieldErrorMessage
                      message={errors.Sweepstake.name.message}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item container alignItems={'center'} xs={12} md={12}>
                <Grid item xs={12} md={6}>
                  <InputLabel
                    required
                    sx={{ fontWeight: 'normal' }}
                    id="Sweepstake.grandPrizeType"
                  >
                    Grand Prize Type
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <GrandPrizeTypeSelect />

                  {errors.Sweepstake?.grandPrizeType?.message && (
                    <FormFieldErrorMessage
                      message={errors.Sweepstake.grandPrizeType.message}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item container alignItems={'center'} xs={12} md={12}>
                <Grid item xs={12} md={6}>
                  <InputLabel
                    required
                    htmlFor="Sweepstake.startDate"
                    sx={{ fontWeight: 'normal' }}
                  >
                    Start Date/Time
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <AdornedDateTimeField
                    name="Sweepstake.startDate"
                    control={control}
                  />
                  {errors.Sweepstake?.startDate?.message && (
                    <FormFieldErrorMessage
                      message={errors.Sweepstake?.startDate.message}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                container
                alignItems={'center'}
                xs={12}
                md={12}
                pb={{ md: 3 }}
              >
                <Grid item xs={12} md={6}>
                  <InputLabel
                    required
                    htmlFor="Sweepstake.endDate"
                    sx={{ fontWeight: 'normal' }}
                  >
                    End Date/Time
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <AdornedDateTimeField
                    name="Sweepstake.endDate"
                    control={control}
                  />
                  {errors.Sweepstake?.endDate?.message && (
                    <FormFieldErrorMessage
                      message={errors.Sweepstake?.endDate.message}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item container alignItems={'center'} xs={12} md={12}>
                <Grid item xs={12} md={6}>
                  <InputLabel
                    required
                    sx={{ fontWeight: 'normal' }}
                    id="Sweepstake.numGrandPrizeWinnersPerTier"
                  >
                    Number of Grand Prize Winners Per Tier
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    {...register('Sweepstake.numGrandPrizeWinnersPerTier', {
                      required: true,
                    })}
                    type="number"
                    placeholder="Enter # of Grand Prize Winners Per Tier"
                    error={isPresent(
                      errors.Sweepstake?.numGrandPrizeWinnersPerTier
                    )}
                  />
                  {errors.Sweepstake?.numGrandPrizeWinnersPerTier?.message && (
                    <FormFieldErrorMessage
                      message={
                        errors.Sweepstake.numGrandPrizeWinnersPerTier.message
                      }
                    />
                  )}
                </Grid>
              </Grid>
              <Grid item container alignItems={'center'} xs={12} md={12}>
                <Grid item xs={12} md={6}>
                  <InputLabel
                    required
                    sx={{ fontWeight: 'normal' }}
                    id="Sweepstake.state"
                  >
                    State
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <StateSelect />

                  {errors.Sweepstake?.state?.message && (
                    <FormFieldErrorMessage
                      message={errors.Sweepstake.state.message}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid
                item
                container
                alignItems={'center'}
                xs={12}
                md={12}
                rowSpacing={3}
                pb={{ md: 3 }}
              >
                <Grid item xs={12} md={6}>
                  <InputLabel
                    id="Sweepstake.url"
                    sx={{ fontWeight: 'normal' }}
                    required
                  >
                    Sweepstake URL
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={12} sx={{ width: '100%' }}>
                  <TextField
                    fullWidth
                    {...register('Sweepstake.url', {
                      required: false,
                    })}
                    error={isPresent(errors.Sweepstake?.url)}
                    sx={{ maxWidth: 'none', width: '100%' }}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                alignItems={'center'}
                xs={12}
                md={12}
                rowSpacing={3}
              >
                <Grid item xs={12} md={12}>
                  <InputLabel
                    id="Sweepstake.description"
                    required
                    sx={{ fontWeight: 'normal' }}
                  >
                    Sweepstake Description
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={6}
                    required
                    error={isPresent(errors.Sweepstake?.description)}
                    placeholder="Enter description of sweepstake"
                    {...register('Sweepstake.description')}
                    sx={{ maxWidth: 'none', width: '100%' }}
                  />
                  <Grid
                    item
                    container
                    direction="row"
                    xs={12}
                    md={12}
                    justifyContent={'flex-end'}
                  >
                    <Typography variant="body-3">500 characters max</Typography>
                  </Grid>
                  {errors.Sweepstake?.description?.message && (
                    <FormFieldErrorMessage
                      message={errors.Sweepstake.description.message}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </ModalFullBody>
          <ModalFullFooter>
            <Button variant="text" onClick={toggleModalIsOpen}>
              Cancel
            </Button>
            <ActivityButton
              active={updateIsActive}
              type="submit"
              variant="contained"
            >
              Save
            </ActivityButton>
          </ModalFullFooter>
        </form>
      </FormProvider>
    </Modal>
  )
}

const NEW_SWEEPSTAKE_DATA_ID = 0

const getStartDate = (sweepstake: Sweepstake) => {
  if (isNil(sweepstake.startDate)) {
    return undefined
  }

  return parseISO(sweepstake.startDate)
}

const getEndDate = (sweepstake: Sweepstake) => {
  if (isNil(sweepstake.endDate)) {
    return undefined
  }

  return parseISO(sweepstake.endDate)
}
